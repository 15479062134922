const paths = {
    dashboard: '/dashboard',
    profile: '/profile',
    auth: {
        login: '/login',
        error: '/error',
        logout: '/logout',
        forgottenPassword: '/forgotten-password'
    },
    settings: '/settings',
    devices: {
        list: '/devices',
        detail: '/devices/detail/:deviceId',
        userDevices: '/devices/:userId'
    },
    stores: {
        list: '/stores',
        detail: '/stores/detail/:storeId',
        new: '/stores/new',
        zoneDetail: '/stores/zones/detail/:zoneId',
    },
    companies: {
        list: '/companies',
        detail: '/companies/detail/:companyId',
        uesrDevices: '/operations/:userId',
        new: '/companies/new'
    },
    notifications: {
        list: '/notifications'
    },
    campaigns: {
        commercial: '/commercial-campaigns',
        commercialNew: '/commercial-campaigns/new',
        commercialDetail: '/commercial-campaigns/detail/:campaignId',
        internal: '/internal-campaigns',
        internalNew: '/internal-campaigns/new',
        internalDetail: '/internal-campaigns/detail/:campaignId',
    },
    users: {
        list: '/users',
        new: '/users/new',
        detail: '/users/detail/:userId'
    },
    streams: '/streams',
    playlists: {
        list: '/playlists',
        detail: '/playlists/detail/:playlistId',
        new: '/playlist/new'
    },
    displays: {
        list: '/displays',
    },
    media: '/media',
    playlist: {
        list: '/playlist',
    },
    changeDashboard: '/changeDashboard',
    invoices: {
        list: '/invoices',
        detail: '/invoices/detail/:invoiceId'
    },
    commissions: 
    {
        list: '/commissions',
        detail: '/commissions/detail/:commissionId'
    },
    operations: {
        list: '/activities'
    },
    groups: {
        list: '/groups'
    }
}

export default paths;