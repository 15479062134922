import { scope, action } from "common/enums/GroupsPermissionEnum"
class AuthGuard {
    grants = [];
    constructor(permissions) {
        this.grants = this.parse(permissions);
    }
    parse(permissions) {
        return permissions.map(p => {
            const match = p.match(/((?:[\w\-_]+)|\*):((?:[\w\-_]+)|\*):((?:all|own|\*))/);
            return (match) ? { resource: match[1], action: match[2], scope: match[3] } : null;
        })
            .filter(p => !!p)
            .sort((a, b) => this.compareGrants(a.resource, b.resource))
            .sort((a, b) => this.compareGrants(a.action, b.action))
            .sort((a, b) => this.compareGrants(a.scope, b.scope))
            .map(p => ({
                resource: new RegExp(p.resource.replace(/\*|all/, '.*')),
                action: new RegExp(p.action.replace(/\*|all/, '.*')),
                scope: ['all', '*'].includes(p.scope) ? 'all' : 'own'
            }))
    }
    compareGrants(valueA, valueB) {
        const isAnyA = ['all', '*'].includes(valueA);
        const isAnyB = ['all', '*'].includes(valueB);
        if (isAnyA && !isAnyB) return -1;
        if (!isAnyA && isAnyB) return 1;
        return 0
    }
    hasPermission(resource, action) {
        const grant = this.grants.find(g => g.resource.test(resource) && g.action.test(action));
        return (grant) ? grant.scope : null;
    }
}

let actions
if (window.localStorage.getItem("permissions")) {
    let permissionLocalStorage = window.localStorage.getItem("permissions");
    let helpPermission = (permissionLocalStorage)?.substring((permissionLocalStorage).indexOf("[") + 1)
    actions = helpPermission.split(',');
} else {
    actions = [
        "profile:list:own"
    ]
}

const ac = new AuthGuard(actions)

export const companyList = ac.hasPermission(scope.COMPANY, action.LIST)
export const companyDetail = ac.hasPermission(scope.COMPANY, action.DETAIL)
export const companyCreate = ac.hasPermission(scope.COMPANY, action.CREATE)
export const companyUpdate = ac.hasPermission(scope.COMPANY, action.UPDATE)
export const companyDelete = ac.hasPermission(scope.COMPANY, action.DELETE)
export const companyManageMembers = ac.hasPermission(scope.COMPANY, action.MEMBERS)

export const deviceList = ac.hasPermission(scope.DEVICE, action.LIST)
export const deviceAssign = ac.hasPermission(scope.DEVICE, action.ASSIGNMENT)
export const deviceDisc = ac.hasPermission(scope.DEVICE, action.DISCOVER)
export const deviceDetail = ac.hasPermission(scope.DEVICE, action.DETAIL)
export const deviceRestart = ac.hasPermission(scope.DEVICE, action.RESTART)
export const deviceEn = ac.hasPermission(scope.DEVICE, action.ENABLE)
export const deviceDis = ac.hasPermission(scope.DEVICE, action.DISABLE)
export const deviceIdentify = ac.hasPermission(scope.DEVICE, action.IDENTIFY)
export const deviceTerminal = ac.hasPermission(scope.DEVICE, action.TERMINAL) //changed


export const storeList = ac.hasPermission(scope.STORE, action.LIST)
export const storeDetail = ac.hasPermission(scope.STORE, action.DETAIL)
export const storeCreate = ac.hasPermission(scope.STORE, action.CREATE)
export const storeUpdate = ac.hasPermission(scope.STORE, action.UPDATE)
export const storeDel = ac.hasPermission(scope.STORE, action.DELETE)

export const zoneList = ac.hasPermission(scope.ZONE, action.LIST)
export const zoneDetail = ac.hasPermission(scope.ZONE, action.DETAIL)
export const zoneCreate = ac.hasPermission(scope.ZONE, action.CREATE)
export const zoneUpdate = ac.hasPermission(scope.ZONE, action.UPDATE)
export const zoneDel = ac.hasPermission(scope.ZONE, action.DELETE)

export const mediaList = ac.hasPermission(scope.MEDIA, action.LIST)
export const mediaUpload = ac.hasPermission(scope.MEDIA, action.UPLOAD)
export const mediaDownload = ac.hasPermission(scope.MEDIA, action.DOWNLOAD)
export const mediaApp = ac.hasPermission(scope.MEDIA, action.APPROVE)
export const mediaRej = ac.hasPermission(scope.MEDIA, action.REJECT)
export const mediaDel = ac.hasPermission(scope.MEDIA, action.DELETE)

export const userList = ac.hasPermission(scope.USER, action.LIST)
export const userCreate = ac.hasPermission(scope.USER, action.CREATE)
export const userDetail = ac.hasPermission(scope.USER, action.DETAIL)
export const userUpdate = ac.hasPermission(scope.USER, action.UPDATE)
export const userBlock = ac.hasPermission(scope.USER, action.BLOCK)
export const userUnblock = ac.hasPermission(scope.USER, action.UNBLOCK)
export const userPasswordReset = ac.hasPermission(scope.USER, action.PASSWORDRESET)

export const dashboardList = ac.hasPermission(scope.OVERVIEW, action.DETAIL)

export const groupList = ac.hasPermission(scope.GROUP, action.LIST)
export const groupDetail = ac.hasPermission(scope.GROUP, action.DETAIL)
export const groupCreate = ac.hasPermission(scope.GROUP, action.CREATE)
export const groupUpdate = ac.hasPermission(scope.GROUP, action.UPDATE)
export const groupDelete = ac.hasPermission(scope.GROUP, action.DELETE)

export const settingsDetail = ac.hasPermission(scope.SETTINGS, action.DETAIL)
export const settingsUpdate = ac.hasPermission(scope.SETTINGS, action.UPDATE)

export const segmentList = ac.hasPermission(scope.SEGMENT, action.LIST)

export const displayList = ac.hasPermission(scope.DISPLAY_GROUP, action.LIST)
export const displayDetail = ac.hasPermission(scope.DISPLAY_GROUP, action.DETAIL)
export const displayCreate = ac.hasPermission(scope.DISPLAY_GROUP, action.CREATE)
export const displayUpd = ac.hasPermission(scope.DISPLAY_GROUP, action.UPDATE)
export const displayDel = ac.hasPermission(scope.DISPLAY_GROUP, action.DELETE)

export const playlistList = ac.hasPermission(scope.PLAYLIST, action.LIST)
export const playlistDetail = ac.hasPermission(scope.PLAYLIST, action.DETAIL)
export const playlistCreate = ac.hasPermission(scope.PLAYLIST, action.CREATE)
export const playlistUpd = ac.hasPermission(scope.PLAYLIST, action.UPDATE)
export const playlistDel = ac.hasPermission(scope.PLAYLIST, action.DELETE)



