export const scope = {
    COMPANY: 'company',
    DEVICE: 'device',
    STORE: 'store',
    ZONE: 'zone',
    USER: 'user',
    PLAYLIST: 'playlist',
    DISPLAY_GROUP: 'display-group',
    MEDIA: 'media',
    GROUP: 'user-group',
    OVERVIEW: 'overview',
}

export const action = {
    LIST: 'list',
    DETAIL: 'detail',
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
    APPROVE: 'approve',
    REJECT: 'reject',
    CANCEL: 'cancel',
    DISCOVER: 'discover',
    RESTART: 'restart',
    EXPORT: 'export',
    ENABLE: 'enable',
    DISABLE: 'disable',
    UPLOAD: 'upload',
    DOWNLOAD: 'download',
    BLOCK: 'block',
    UNBLOCK: 'unblock',
    PASSWORDRESET: 'password-reset',
    ASSIGNMENT: 'manage-assignment',
    MEMBERS: 'manage-members',
    IDENTIFY: 'identify',
    TERMINAL: 'terminal',
    UPDATE_MEDIA_CAMPAIGNS: 'media-campaigns',
    UPDATE_MEDIA_STREAMS: 'media-streams',
    UPDATE_MEDIA_JINGLE: 'media-jingle'
}