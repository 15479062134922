import QueryTable from 'components-lib/QueryTable/QueryTableRefresh';
import Button from "components/CustomButtons/Button.js";
import React, { useState } from "react";
import { injectIntl } from 'react-intl';
import { Formik } from "formik";
import { Grid } from "@material-ui/core";
import Modal from "components-lib/Modal/Modal";
import FormikField from "components-lib/FormikField/FormikField";
import getValidationSchema from "./validationSchema";
import SELECT_PLAYLISTS from 'queries/PlaylistsQueries/selectPlaylists';
import DISPLAY_GROUP_UPDATE from 'queries/DisplaysQueries/dsiplayGroupUpdate';
import { pathOr } from "rambda";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { getApolloClient } from "configFiles/apollo";
import { displayUpd, displayDel } from 'configFiles/roles'

export const DisplayTable = (props) => {
    const { history, queryVariables, data, loadData, refetch, intl, defaultFilter = null, numberOfRows, queryDataPath = ['displayGroupsTable', 'items'] } = props
    const { enqueueSnackbar } = useSnackbar();
    const client = getApolloClient(enqueueSnackbar, history, intl);


    const [assignModalOpen, setAssignModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [initialData] = useState({});
    const [displayGroupId, setDisplayGroupId] = useState(null)
    const [playlists, setPlaylists] = useState([{}]);
    const { data: playlistsData } = useQuery(SELECT_PLAYLISTS);
    const [displayGroupUpdate] = useMutation(DISPLAY_GROUP_UPDATE);

    const handleSetPlaylistOptions = async (id) => {
        try {
            const result = await client.query({
                query: SELECT_PLAYLISTS,
                variables: {
                    filter: [
                        {
                            companyId: {
                                eq: Number(id)
                            }
                        }
                    ]
                }
            });
            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            let playlistSelectItems = (result.data?.playlists?.items).map((playlist) => {
                return {
                    value: pathOr('', 'id', playlist),
                    label: `${pathOr('', 'bid', playlist)} - ${pathOr('', 'name', playlist)}`
                }
            })
            setPlaylists(playlistSelectItems);

        } catch (error) {
            throw error;
        }
    };

    const handleDisplayGroupUpdate = (values, resetForm) => {
        displayGroupUpdate({
            variables: {
                id: displayGroupId,
                input: {
                    playlistId: (values?.playlist) ? values?.playlist : null
                }
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: 'displayList.assignPlaylist.success' }), { variant: 'success' });
            resetForm();
            refetch();
            setAssignModalOpen(false);
            setDeleteModalOpen(false);
        }).catch((err) => {
            console.log('[error]', err);
            refetch();
        })
        refetch();
        setDeleteModalOpen(false);
        setAssignModalOpen(false);
    };

    const renderModalButtons = (formikProps) => {
        return (
            <>
                <Button
                    disabled={!formikProps.values.playlist}
                    size="sm"
                    color="success"
                    onClick={(e) => formikProps.handleSubmit()}
                    round
                >
                    {intl.formatMessage({ id: "displayList.modal.confirmButton" })}
                </Button>

                <Button
                    size="sm"
                    color="primary"
                    onClick={() => { setAssignModalOpen(false); formikProps.resetForm() }}
                    round
                >
                    {intl.formatMessage({ id: "displayList.modal.cancelButton" })}
                </Button>
            </>
        );
    };

    const renderDeleteModalButtons = () => {
        return (
            <>
                <Button
                    size="sm"
                    color="warning"
                    onClick={(e) => handleDisplayGroupUpdate()}
                    round
                >
                    {intl.formatMessage({ id: "displayList.table.actions.delete" })}
                </Button>
                <Button
                    size="sm"
                    color="primary"
                    onClick={() => { setDeleteModalOpen(false) }}
                    round
                >
                    {intl.formatMessage({ id: "displayList.modal.cancelButton" })}
                </Button>
            </>
        );
    };

    return (
        <>
            <QueryTable
                data={data}
                loadData={loadData}
                numberOfRows={numberOfRows}
                permanentFilter={defaultFilter}
                queryDataPath={queryDataPath}
                queryVariables={queryVariables}
                columns={[
                    {
                        Header: intl.formatMessage({ id: 'displayList.table.ID' }),
                        accessor: 'bid',
                        sortKey: 'bid',
                        filterKey: 'bid',
                        filterOperator: 'contains'
                    },
                    {
                        Header: intl.formatMessage({ id: 'displayList.table.name' }),
                        accessor: "name",
                        sortKey: "name",
                        filterKey: 'name',
                        filterOperator: 'contains'
                    },
                    {
                        Header: intl.formatMessage({ id: 'displayList.table.playlist' }),
                        accessor: (rowData) => rowData.playlist ? rowData.playlist : <p> - </p>,
                        sortKey: "playlist",
                        filterKey: "playlist",
                        filterOperator: 'contains'
                    },
                    {
                        Header: intl.formatMessage({ id: 'displayList.table.company' }),
                        accessor: 'company',
                        sortKey: "company",
                        filterKey: "company",
                        filterOperator: 'contains'
                    },
                    {
                        Header: intl.formatMessage({ id: 'displayList.table.description' }),
                        accessor: (rowData) => rowData.description ? rowData.description : <p> - </p>,
                        sortKey: "description",
                        filterKey: "description",
                        filterOperator: 'contains'
                    },
                    (displayUpd) ?
                        {
                            Header: intl.formatMessage({ id: 'displayList.table.actions' }),
                            accessor: (rowData) => <>
                                <Button round size="sm" color="info"
                                    onClick={() => { setDisplayGroupId(rowData.id); handleSetPlaylistOptions(rowData.companyId); setAssignModalOpen(true) }}>{(rowData.playlist)
                                        ? intl.formatMessage({ id: 'displayList.table.actions.change' })
                                        : intl.formatMessage({ id: 'displayList.table.actions.assign' })
                                    }</Button>
                                {(rowData.playlist) && <Button round size="sm" color="danger"
                                    onClick={() => { setDisplayGroupId(rowData.id); setDeleteModalOpen(true) }}>
                                    {intl.formatMessage({ id: 'displayList.table.actions.delete' })}
                                </Button>
                                }
                            </>
                        } : { Header: " " }
                ]}
            />
            <Formik
                onSubmit={(values, { resetForm }) => handleDisplayGroupUpdate(values, resetForm)}
                initialValues={initialData}
                validationSchema={getValidationSchema(intl)}
            >
                {(formikProps) => (
                    <Modal
                        title={intl.formatMessage({ id: "displayList.modal.title" })}
                        open={assignModalOpen}
                        onClose={() => { setAssignModalOpen(false); formikProps.resetForm() }}
                        actions={renderModalButtons(formikProps)}
                        fullWidth
                    >
                        <Grid container spacing={1}>
                            <Grid item sm={12}>
                                <FormikField
                                    type="select"
                                    name="playlist"
                                    labelText={intl.formatMessage({ id: "displayList.modal.playlist" })}
                                    options={playlists}
                                    formikProps={formikProps}
                                    highlightChange
                                />
                            </Grid>
                        </Grid>
                    </Modal>
                )}

            </Formik>

            <Modal
                title={intl.formatMessage({ id: "displayList.deleteModal.title" })}
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                actions={renderDeleteModalButtons()}
                fullWidth
            />
        </>
    )
}

export default injectIntl(DisplayTable);