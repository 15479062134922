const defaultValidationErrors = {
    'validation.required': 'Toto pole je povinné',
    'validation.number': 'Toto pole musí obsahovať len čísla',
    'validation.number.min': 'Minimálna hodnota pre toto pole je {0}',
    'validation.number.max': 'Maximálna hodnota pre toto pole je {0}',
    'validation.number.integer': 'Toto pole musí obsahovať celé číslo',
    'validation.number.positive': 'Hodnota tohto poľa musí byť vyššia ako 0',
    'validation.email': 'Emailová adresa nie je v správnom tvare',
    'validation.phoneNumber': 'Telefónne číslo nie je v správnom tvare (+421903123123)',
    'validation.spaces': 'Telefónne číslo nesmie obsahovať medzery (+421903123123)',
    'validation.iban': 'IBAN je v nesprávnom tvare',
    'validation.date': 'Dátum je v nesprávnom tvare',
    'validation.graterDate': 'Konečný dátum musí byť väčší ako počiatočný',
    'validation.pastDate': 'Dátum a čas musí byť vyšší ako aktuálny',
    'validation.string.max': 'Maximálna dĺžka tohto poľa je {0} znakov',
    'validation.string.exact': 'Toto pole musí obsahovať presne {0} znakov'
};

const queryTable = {
    'queryTable.select.all': 'Všetko',
    'queryTable.filter.placeholder': 'Filter..',
    "queryTable.filter.dateBetween": "Od - Do",
    "queryTable.filter.setDateRange": "Nastaviť časové rozmedzie: ",
    "queryTable.filter.pickerStart": "Dátum od",
    "queryTable.filter.pickerEnd": "Dátum do",
};

const apiErrors = {
    'apiErrors.InsufficientPermissionsError': 'Na túto sekciu nemáte oprávnenie',
    'apiErrors.INTERNAL_SERVER_ERROR': 'Nastala neočakávana chyba',
    'apiErrors.GRAPHQL_VALIDATION_FAILED': 'Nastala interná chyba databázy',
    'apiErrors.UnauthorizedUserError': 'Platnosť vášho tokenu vypršala alebo nemáte právo na túto akciu',
    'apiErrors.InvalidRequestError': 'Odoslané dáta obsahujú neplatné hodnoty',
    'apiErrors.AccountDoesNotExistsError': 'Účet so zadanou e-mailovou adresou neexistuje',
    'apiErrors.AccountExistsError': 'Účet so zadanou e-mailovou adresou už existuje',
    'apiErrors.PasswordResetPendingError': 'Na e-mail už bol odoslaný odkaz pre zmenu hesla',
    'apiErrors.PasswordResetNotAllowedError': 'Tento účet nepodporuje resetovanie hesla',
    'apiErrors.InvalidLoginError': 'Zadaný email alebo heslo je nesprávne.',
    'apiErrors.InvalidLoginMethodError': 'Účet so zadaným emailom nepodporuje použitú autentifikačnú metódu.',
    'apiErrors.UnactivatedAccountError': 'Účet vyžaduje aktiváciu.',
    'apiErrors.BlockedAccountError': 'Účet bol zablokovaný administrátorom.',
    'apiErrors.DeviceOfflineError': 'Nie je možné vykonať akciu lebo zariadenie je Offline',
    'apiErrors.EntityDoesNotExistsError': 'Požadovaný záznam neexistuje',
    'apiErrors.EntityUniqueConstraintError': 'Zariadne s rovnakým názvom už existuje',
    'apiErrors.CannotPerformOnOwnAccountError': 'Táto operácia nemôže byť vykonaná na vlastnom účte',
    'apiErrors.CannotRemoveEntityInUseError': 'Nemôžete odstrániť túto entitu, pretože sa niekde používa',
    'apiErrors.PasswordChangeError': 'Pôvodné heslo je nesprávne',
    'apiErrors.WeakPasswordError': 'Heslo musí byť aspoň 8 znakov dlhé a musí obsahovať aspoň jedno číslo, aspoň jeden malý a aspoň jeden veľký znak',
    'apiErrors.SubscriptionNotActivatedError': 'Používateľ nemá aktivované žiadne predplatné',
    'apiErrors.InvalidInputDataError': 'Niektorý zo vstupných údajov nie je správny',
};

const routes = {
    'routes.dashboard': 'Prehľad',
    'routes.profile': 'Profil',
    'routes.devices': 'Prehrávače',
    'routes.devices.detail': 'Detail Prehrávača',
    'routes.campaigns.freeFoto': 'FF kampane',
    'routes.campaigns.qr': 'Kupónové kampane',
    "routes.campaigns.new": "Nová kampaň",
    "routes.campaigns.detail": "Detail kampane",
    'routes.stores': 'Prevádzky',
    'routes.stores.new': 'Nová prevádzka',
    "routes.stores.detail": "Detail prevádzky",
    'routes.notifications': 'Notifikácie',
    'routes.users': 'Používatelia',
    'routes.users.new': 'Nový používateľský účet',
    'routes.users.detail': 'Detail pužívateľa',
    'routes.orders': 'Objednávky',
    'routes.orders.detail': 'Detail objednávky',
    "routes.companies": "Spoločnosti",
    "routes.companies.detail": "Detaily spoločnosti",
    "routes.companies.new": "Nová spoločnosť",
    'routes.payments': 'Platby',
    'routes.media': 'Média',
    'routes.playlist': 'Playlist',
    'routes.playlists': 'Playlisty',
    'routes.playlists.detail': 'Playlisty',
    'routes.playlists.new': 'Playlisty',
    'routes.displays': 'Skupiny displejov',
    "routes.invoices": "Fakturácia",
    'routes.invoices.detail': 'Detail platby',
    "routes.commissions": "Provízie",
    "routes.commissions.detail": "Detail Provízie",
    "routes.operations": "Operácie",
    'routes.login': 'Prihlásenie',
    'routes.settings': 'Nastavenia',
    'routes.logout': 'Odhlásiť',
    'routes.forgottenPassword': 'Zabudnuté heslo',
    'routes.loading': 'Načítavam...',
    'routes.groups': 'Skupiny'
};

const usersPage = {
    'usersPage.title': 'Používatelia',
    'usersPage.actions.newPartner': 'NOVÝ PARTNER',
    'usersPage.actions.newAdvertiser': 'NOVÝ INZERENT',
    'usersPage.actions.newMaintainer': 'NOVÝ SPRÁVCA',
    'usersPage.actions.newAccount': 'NOVÝ ÚČET',
    'usersPage.table.title': 'Zoznam požívateľov',
    'usersPage.table.id': 'ID',
    'usersPage.table.name': 'Meno',
    'usersPage.table.company': 'Spoločnosť',
    'usersPage.table.email': 'E-mail',
    'usersPage.table.role': 'Skupiny',
    'usersPage.table.status': 'Stav',
    'usersPage.table.credits': 'Kredity',
    'usersPage.table.status.activated': 'AKTÍVNY',
    'usersPage.table.status.blocked': 'BLOKOVANÝ',
    'usersPage.table.status.notActive': 'NEAKTÍVNY',
    'usersPage.table.subscription': 'Predplatné',
    'usersPage.table.actions': 'Akcie',
    'usersPage.table.detail': 'DETAIL',
};

const createUserPage = {
    'createUser.title': 'Nový účet',
    'createUser.submit': 'VYTVORIŤ',
    'createUser.cancel': 'ZRUŠIŤ',
    'createUser.company.title': 'Údaje o spoločnosti',
    'createUser.company.name': 'Názov spoločnosti',
    'createUser.company.address': 'Adresa',
    'createUser.company.city': 'Mesto',
    'createUser.company.postal': 'PSČ',
    'createUser.company.country': 'Krajina',
    'createUser.company.country.slovakia': 'Slovensko',
    'createUser.contact.title': 'Používateľ',
    'createUser.contact.firstName': 'Meno',
    'createUser.contact.lastName': 'Priezvisko',
    'createUser.contact.birth': 'Dátum narodenia',
    'createUser.contact.gender': 'Pohlavie',
    'createUser.contact.gender.male': 'Muž',
    'createUser.contact.gender.female': 'Žena',
    'createUser.contact.gender.unspecified': 'Neuvedené',
    'createUser.contact.email': 'E-mail',
    'createUser.contact.phone': 'Telefón (Formát: +421903123123)',
    'createUser.identificators.title': 'Identifikátory spoločnosti',
    'createUser.identificators.ico': 'IČO',
    'createUser.identificators.dic': 'DIČ',
    'createUser.identificators.icDph': 'IČ DPH',
    'createUser.identificators.reg': 'Registrový súd',
    'createUser.bank.title': 'Bankové údaje',
    'createUser.bank.iban': 'IBAN',
    'createUser.bank.bic': 'BIC/SWIFT',
    'createUser.bank.name': 'Banka',
    'createUser.success': 'Účet {0} {1} bol vytvorený',
};

const settingsPage = {
    "settings.title": "Nastavenia",
    "settings.update.success": "Systémové nastavenia boli aktualizované",
    "settings.globalSettings.title": "Systémové nastavenia",
    "settings.globalSettings.creditValue": "Hodnota 1 kreditu (EUR)",
    "settings.globalSettings.defaultSpotPlaybackPrice": "Celková cena za prehratie spotu (C)",
    "settings.globalSettings.commissionRate": "Celková výška provízie (%)",
    'settings.save': 'Uložiť Zmeny',
    'settings.cancel': 'Zrušiť'
};

const userDetail = {
    'userDetail.title': '{userName}',
    'userDetail.accountStatus': 'Stav konta',
    'userDetail.ordersCount': 'Počet objednávok',
    'userDetail.printedPhotosCount': 'Počet vytlačených fotografií',
    'userDetail.contactInfo': 'Používateľ',
    'userDetail.contactInfo.create': 'Kontaktné údaje',
    'userDetail.orders': 'Objednávky',
    'userDetail.payments': 'Platby',
    'userDetail.activityHistory': 'História aktivity',
    'userDetail.unblockUser.modalTitle': 'Odblokovanie účtu',
    'userDetail.unblockUser.modalText': 'Chystáte sa odblokovať účet. Naozaj chcete pokračovať?',
    'userDetail.unblockUser.modalConfirm': 'Odblokovať',
    'userDetail.blockUser.modalTitle': 'Naozaj chcete zablokovať tento účet?',
    'userDetail.blockUser.modalSubtitle': 'Uveďte dôvod blokovania',
    'userDetail.blockUser.modalConfirm': 'ZABLOKOVAŤ',
    'userDetail.deleteUser.modalTitle': 'Naozaj chcete odstrániť toento účet?',
    'userDetail.deleteUser.modalConfirm': 'ODSTRÁNIŤ',
    'userDetail.headerActions.blockUser': 'BLOKOVAŤ ÚČET',
    'userDetail.headerActions.allowUser': 'ODBLOKOVAŤ ÚČET',
    'userDetail.headerActions.resetPassword': 'RESETOVAŤ HESLO',
    'userDetail.headerActions.deleteUser': 'ODSTRÁNIŤ ÚČET',
    'userDetail.accountStatus.paymentList': 'ZOZNAM PLATIEB',
    'userDetail.contactInfo.firstName': 'Meno',
    'userDetail.contactInfo.lastName': 'Priezvisko',
    'userDetail.contactInfo.gender': 'Pohlavie',
    'userDetail.contactInfo.email': 'E-mail',
    'userDetail.orders.id': 'ID',
    'userDetail.orders.date': 'Dátum',
    'userDetail.orders.size': 'Objem',
    'userDetail.orders.actions': 'Akcie',
    'userDetail.orders.detail': 'Detail',
    'userDetail.orders.list': 'ZOZNAM OBJEDNÁVOK',
    'userDetail.transactions.list': 'ZOZNAM PLATIEB',
    'userDetail.invoice.pair': 'PÁROVAŤ FAKTÚRU',
    'userDetail.accountStatus.title': 'Stav konta',
    'userDetail.accountStatus.registrationService': 'Registračná služba',
    'userDetail.accountStatus.blocation': 'Blokácia',
    'userDetail.provision': 'Stav konta',
    'userDetail.processedPhotosCount': 'Počet spracovaných fotografií',
    'userDetail.photomatsCount': 'Počet prehrávačov',
    'userDetail.photomatsList': 'Prehrávače',
    'userDetail.photomats.id': 'ID',
    'userDetail.photomats.status': 'Stav',
    'userDetail.photomats.hearthbeat': 'Heartbeat',
    'userDetail.photomats.actions': 'Akcie',
    'userDetail.photomats.showList': 'ZOZNAM PREHRÁVAČOV',
    'userDetail.totalSales': 'Celkový obrat',
    'userDetail.transactions': 'Platby',
    'userDetail.campaigns': 'Kampane',
    'userDetail.activeFreePhotoCampaigns': 'Aktívne FreeFoto kampane',
    'userDetail.totalFreePhotoCampaigns': 'Všetky FreeFoto kampane',
    'userDetail.activeQrCouponsCampains': 'Aktívne sady kupónov',
    'userDetail.totalQrCouponsCampains': 'Všetky sady kupónov',
    'userDetail.buttons.cancel': 'ZRUŠIŤ',
    'userDetail.buttons.confirm': 'ULOŽIŤ ZMENY',
    'userDetail.resetPassword.success': 'Na email: {0} bol odoslaný odkaz na resetovanie hesla',
    'userDetail.userUpdate.success': 'Informácie o účte boli aktualizované',
    "userDetail.statusCard.title": "Základné informácie",
    "userDetail.statusCard.status": "Stav konta",
    "userDetail.statusCard.numberOfCredits": "Kredit na kupónovom účte",
    "userDetail.statusCard.numberOfSubscriptionCredits": "Kredit na predplatnom účte",
    "userDetail.statusCard.subscriptionStatus": "Typ predplatného",
    "userDetail.statusCard.subscriptionStatus.activated": "Aktívny",
    "userDetail.statusCard.subscriptionStatus.unactivated": "bez predplatného",
    "userDetail.statusCard.subscriptionStatus.canceled": "zrušené",
    "userDetail.statusCard.numberOfOrders": "Počet objednávok",
    "userDetail.statusCard.numberOfPhotos": "Počet vytlačených fotografií",
    "userDetail.statusCard.registrationDate": "Dátum registrácie",
    "userDetail.statusCard.registrationType": "Typ registrácie",
    "userDetail.statusCard.lastActivity": "Posledná aktivita",
    "userDetail.statusCard.creditTransfer": "Transferovať QR kredit",
    "userDetail.statusCard.subscriptionDelete": "Zrušiť predplatné",
    "userDetail.statusCard.operationList": "Zoznam operácií",
    "userDetail.statusCard.deleteModal.text": "Naozaj chcete používateľovi zrušiť predplatné?",
    "userDetail.statusCard.sourceUser": "Zdrojový používateľ",
    "userDetail.statusCard.destinationUser": "Cieľový používateľ",
    "userDetail.statusCard.sendCredits": "Počet transferovaných kreditov:",
    "userDetail.statusCard.remains": "Ostáva",
    "userDetail.statusCard.credits": "Kreditov",
    "userDetail.statusCard.confirmButton": "Potvrdiť",
    "userDetail.statusCard.lessThanZero": "Používateľ nemá dostatočný počet",
    "userDetail.statusCard.chooseUser": "Vyberte používateľa...",
    "userDetail.statusCard.maxCredits": "Počet kreditov: max.",
    "userDetail.statusCard.validCredits": "Number of credits must be less than",
    "userDetail.statusCard.modalTitle": "Chystáte sa transferovať kredit medzi používateľmi",
    "userDetail.statusCard.successTransform": "Kredity boli úspešne prevedené medzi použivateľmi",
    "userDetail.statusCard.successDeactivateSubscription": "Predplatné používateľa bolo zrušené",
    "userDetail.companyTable.title": "Spoločnosti",
    "userDetail.storeTable.title": "Prevádzky",
    "userDetail.toast.block.success": "Účet bol zablokovaný",
    "userDetail.toast.unblock.success": "Účet bol odblokovaný",
    "userDetail.toast.delete.success": "Účet bol odstránený",
    "userDetail.toast.delete.fail": "Táto akcia nie je povolená",
};

const groups = {
    'groups.title': 'Skupiny a oprávnenia',
    'groups.groups.title': 'Skupiny',
    'groups.update.title': 'Nastavenie oprávnení',
    'groups.table.name': 'Názov',
    'groups.table.action': 'Akcia',
    'groups.table.actions': 'Akcie',
    'groups.table.disabled': 'Žiadne',
    'groups.table.own': 'Vlastné',
    'groups.table.all': 'Všetky',
    'groups.table.accounts': 'Počet účtov',
    'groups.update.setGroup': 'Zvolená skupina',
    'groups.choosenGroup': 'Vyberte skupinu...',
    'groups.form.name': 'Názov skupiny',
    'groups.form.description': 'Popis skupiny',
    'groups.button.create': 'Vytvoriť',
    'groups.button.delete': 'Odstrániť',
    'groups.button.addGroup': 'Pridať skupinu',
    'groups.deleteModal.title': 'Naozaj chcete odstrániť túto skupinu?',
    'groups.deleteModal.body': 'Počet používateľov v skupine je',
    'groups.deleteModal.body2': 'Ak skupinu odstránite, títo použivatelia stratia práva patriace tejto skupine',
    'groups.createModal.title': 'Vytvorenie skupiny',
    'groups.success.update': 'Detaily skupiny boli aktualizované',
    'groups.success.create': 'Skupina bola vytvorená',
    'groups.success.delete': 'Skupina bola odstránená',

    'groups.scope.company': 'Spoločnosť',
    'groups.scope.device': 'Prehrávač',
    'groups.scope.store': 'Prevádzka',
    'groups.scope.zone': 'Zóna',
    'groups.scope.playlist': 'Playlist',
    'groups.scope.display-group': 'Skupina displejov',
    'groups.scope.commercial-campaign': 'Komerčná kampaň',
    'groups.scope.internal-campaign': 'Interná kampaň',
    'groups.scope.user': 'Používateľ',
    'groups.scope.invoice': 'Faktúra',
    'groups.scope.payout': 'Dobropis',
    'groups.scope.commission': 'Provízia',
    'groups.scope.operation': 'Operácie',
    'groups.scope.stream': 'Stream',
    'groups.scope.media': 'Médiá',
    'groups.scope.user-group': 'Skupina',
    'groups.scope.settings': 'Nastavenia',
    'groups.scope.overview': 'Prehľad',

    'groups.action.list': 'Zobrazenie',
    'groups.action.detail': 'Detail',
    'groups.action.create': 'Vytvorenie',
    'groups.action.update': 'Úprava',
    'groups.action.approve': 'Schválenie',
    'groups.action.reject': 'Zamietnutie',
    'groups.action.cancel': 'Ukončenie',
    'groups.action.discover': 'Obnovenie/ Prehľadanie',
    'groups.action.restart': 'Reštart',
    'groups.action.delete': 'Odstránenie',
    'groups.action.enable': 'Pripojenie',
    'groups.action.disable': 'Odpojenie',
    'groups.action.upload': 'Nahratie',
    'groups.action.download': 'Spustenie / stiahnutie',
    'groups.action.export': 'Export',
    'groups.action.block': 'Zablokovanie',
    'groups.action.unblock': 'Odblokovanie',
    'groups.action.identify': 'Identifikovanie',
    'groups.action.terminal': 'Terminál',
    'groups.action.password-reset': 'Resetovanie hesla',
    'groups.action.manage-assignment': 'Priradenie prevádzke',
    'groups.action.manage-members': 'Pridanie / odobratie používateľov',
    'groups.action.media-campaigns': 'Autorizácia kampaní',
    'groups.action.media-streams': 'Zmena rádio streamu',
    'groups.action.media-forbiden-topics': 'Správa zakázaných tém',
    'groups.action.media-jingle': 'Zmena jinglov',
}

const devices = {
    'deviceList.title': 'Prehrávače',
    'deviceList.header': 'Zoznam prehrávačov',
    'deviceList.table.title': 'Zoznam prehrávačov',
    'deviceList.assignDevice': 'PRIRADIŤ PREHRÁVAČ',
    'deviceList.discoverDevice': 'PREHĽADAŤ SKLAD',
    'deviceList.table.active': 'Aktívny',
    'deviceList.table.changePartnerSuccess': 'Zmena partnera úspešná',
    'deviceList.table.changePartnerFailed': 'Zmena partnera neúspešná',
    'deviceList.table.ID': 'ID',
    'deviceList.table.state': 'Stav',
    'deviceList.table.heartbeat': 'Heartbeat',
    'deviceList.table.partner': 'Partner',
    'deviceList.table.type': 'Typ',
    'deviceList.table.playerStatus': 'Video',
    'deviceList.table.clientVersion': 'Verzia',
    'deviceList.table.company': 'Spoločnosť',
    'deviceList.table.store': "Prevádzka",
    'deviceList.table.printedPhotos': 'Počet výtlačkov',
    'deviceList.table.remainingPhotos': 'Zostáva',
    'deviceList.table.placement': 'Umiestnenie',
    'deviceList.table.actions': 'Akcie',
    'deviceList.table.actions.detail': 'Detail',
    'deviceList.table.balenaId': 'Balena ID',
    'deviceList.enabledToggle': 'Prevádzka',
    'deviceList.disabledToggle': 'Sklad',
    'deviceList.assignModal.title': 'Priradiť prehrávač',
    'deviceList.assignModal.assign': 'PRIRADIŤ',
    'deviceList.assignModal.cancel': 'ZRUŠIŤ',
    'deviceList.assignModal.partner': 'Vyberte partnera...',
    'deviceList.assignModal.device': 'Vyberte prehrávač...',
    'deviceList.discoverModal.title': 'Stav skladu',
    'deviceList.discoverModal.textNo': 'Na sklade nepribudli žiadne nové Prehrávače',
    'deviceList.discoverModal.textYes': 'Počet nových prehrávačov na sklade: {0}',
    'devicesList.filter.active': 'Aktívny',
    'devicesList.filter.nonActive': 'Neaktívny',
    'deviceDetail.title': 'Prehrávač',
    'deviceDetail.status.deviceState': 'Stav prehrávača',
    'deviceDetail.status.lastHeartbeat': 'Posledný heartbeat',
    'deviceDetail.status.remainingPhotoCount': 'Zostávajúci počet fotografií',
    'deviceDetail.status.printerCounter': 'Počítadlo tlačiarne',
    'deviceDetail.status.photosPerMonth': 'Priemerne fotiek za mesiac',
    'deviceDetail.status.restart': 'REŠTARTOVAŤ',
    'deviceDetail.status.deactivate': 'DEAKTIVOVAŤ',
    'deviceDetail.status.identify': 'Identifikovať',
    'deviceDetail.status.console': 'Terminál',
    'deviceDetail.status.reportError': 'Nahlásiť poruchu',
    'deviceDetail.status.activate': 'Aktivovať',
    'deviceDetail.status.back': 'Späť',
    'deviceDetail.status.restartModal.title': 'Reštartovať prehrávač',
    'deviceDetail.status.restartModal.text': 'Naozaj si prajete reštartovať tento prehrávač?',
    'deviceDetail.status.deactivateModal.title': 'Deaktivovať prehrávač',
    'deviceDetail.status.deactivateModal.text': 'Naozaj si prajete deaktivovať tento prehrávač?',
    'deviceDetail.status.activateModal.title': 'Aktivovať prehrávač',
    'deviceDetail.status.activateModal.text': 'Naozaj si prajete aktivovať tento prehrávač?',
    'deviceDetail.partner.title': 'Partner',
    'deviceDetail.partner.printerPhotos': 'Fotografie z tlačiarne',
    'deviceDetail.partner.orderPhotos': 'Fotografie z objednávok',
    'deviceDetail.partner.changePartner': 'ZMENIŤ PARTNERA',
    'deviceDetail.partner.removePartner': 'ODSTRÁNIŤ PARTNERA',
    'deviceDetail.partner.detailPartner': 'DETAIL PARTNERA',
    'deviceDetail.partner.changePartnerModal.title': 'Zmeniť partnera',
    'deviceDetail.partner.changePartnerModal.action': 'ZMENIŤ',
    'deviceDetail.partner.removePartnerModal.title': 'Odstrániť partnera',
    'deviceDetail.partner.removePartnerModal.text': 'Naozaj si prajete odstrániť partnera',
    'deviceDetail.partner.removePartnerModal.action': 'ODSTRÁNIŤ',
    'deviceDetail.partner.back': 'Späť',
    'deviceDetail.orders.title': 'Objednávky',
    'deviceDetail.orders.ordersList': 'Zoznam objednávok',
    'deviceDetail.orders.table.id': 'ID',
    'deviceDetail.orders.table.date': 'Dátum',
    'deviceDetail.orders.table.volume': 'Objem',
    'deviceDetail.orders.table.actions': 'Akcie',
    'deviceDetail.orders.table.actions.detail': 'Detail',
    'deviceDetail.provisions.title': 'Provízie',
    'deviceDetail.provisions.totalProvision': 'Celková provízia',
    'deviceDetail.provisions.provisionsList': 'Zoznam provízií',
    'deviceDetail.address.title': 'Adresa umiestnenia',
    'deviceDetail.address.placeholder': "Zadajte adresu",
    'deviceDetail.address.undefinedLocation': "Poloha neurčená",
    'deviceDetail.partner.removePartnerSuccessful': 'Odstránenie partnera úspešné',
    'deviceDetail.partner.removePartnerFailed': 'Odstránenie partnera neúspešné',
    'deviceDetail.partner.changePartnerSuccessful': 'Zmena partnera úspešná',
    'deviceDetail.partner.changePartnerFailedl': 'Zmena partnera neúspešná',
    'deviceDetail.status.deviceDeactivationFailed': 'Deaktivácia neúspešná',
    'deviceDetail.status.deviceDeactivationSuccessful': 'Deaktivácia úspešná',
    'deviceDetail.status.deviceActivationSuccessful': 'Aktivácia úspešná',
    'deviceDetail.status.deviceActivationFailed': 'Deaktivácia neúspešná',
    'deviceDetail.status.deviceRestartSuccessful': 'Reštartovanie úspešné',
    'deviceDetail.status.deviceRestartFailed': 'Reštartovanie neúspešné',
    'deviceDetail.partner.changeAddressFailed': 'Zmena adresy neuspešná',
    'deviceDetail.partner.changeAddressSuccessful': 'Zmena adresy úspešná',
    "deviceDetail.player.changePlayer.title": "Zmeniť prehrávač",
    "deviceDetail.player.changePlayer.text": "K prevádzke {názov prevádzky} ({ID prevádzky}) je priradený prehrávač {ID Prehrávača}. Vyberte nový prehrávač: ",
    "deviceDetail.player.newPlayer.text": "Vyberte prehrávač, ktorý chcete priradiť tejto prevádzke",
    "deviceDetail.player.changePlayer.change": "Zmeniť",
    "deviceDetail.player.assignPlayer.title": "Priradiť prehrávač",
    "deviceDetail.player.withoutPlayer.title": "Tejto prevadzke nie je priradený žiadny prehrávač",
    "deviceDetail.player.assignPlayer.text": "K prevádzke {name of the operation} ({ID of operation}) priradiť nový prehrávač: ",
    "deviceDetail.player.assignPlayer.assign": "Priradiť",
    "deviceDetail.player.assignPlayer.change": "Zmeniť",
    "deviceDetail.player.assignPlayerSuccessful": "Priradenie Prehrávača bolo úspešné",
    "deviceDetail.store.detail": "Detail prevádzky",
    "deviceList.removeDevice": "Odobrať",
    "deviceList.assignModal.store": "Vyberte prevádzku...",
    "deviceDetail.status.removeModal.text-1": "Naozaj chcete odobrať prehrávač",
    "deviceDetail.status.removeModal.text-2": "z prevádzky",
    "deviceDetail.status.remove": "Odobrať",
    "deviceDetail.status.deviceRemoveSuccessful": "Prehrávač úspešne odobratý",
    "deviceDetail.deviceStatusCard.deviceActivated": "Aktivovaný",
    "deviceDetail.deviceStatusCard.deviceDeactivated": "Deaktivovaný",
    "deviceDetail.deviceStatusCard.deviceApp": "Prehrávač aplikácia",
    "deviceDetail.deviceStatusCard.deviceAppRunning": "Spustená",
    "deviceDetail.deviceStatusCard.deviceAppStopped": "Zastavená",
    "deviceDetail.status.removeModal.title": "Odobrať prehrávač",
    "deviceDetail.success.browseStock": "Sklad s prehrávačmi bol prehľadaný",
    'deviceDetail.status.playbackStatus': 'Stav prehrávania',
    "deviceDetail.devicePlayback.noplay": "Neprehráva sa",
    "deviceDetail.devicePlayback.online": "Prehráva sa",
    "deviceDetail.devicePlayback.error": "Error",
    "deviceDetail.statusCard.volume": "Hlasitosť prehrávania",

    'deviceList.playerStatus.tooltip.online': 'Video sa prehráva',
    'deviceList.playerStatus.tooltip.offline': 'Video sa neprehráva',
    'deviceList.deviceStatus.tooltip.online': 'Prehrávač je online',
    'deviceList.deviceStatus.tooltip.offline': 'Prehrávač je offline',
}


const campaigns = {
    "campaigns.removeModal.title": "Ukončiť kampaň",
    "campaigns.removeModal.yes": "Odstrániť",
    "campaigns.removeModal.no": "Zrušiť",
    "campaigns.new": "Nová kampaň",
    "campaigns.duplicate.button": "Duplikovať kampaň",
    "campaigns.end.button": "Ukončiť kampaň",
    "campaigns.detail": "Detail",
    "campaigns.freefoto.title": "Free Foto kampane",
    "campaigns.freefoto.header": "Zoznam FF kampaní",
    "campaigns.qr.title": "Kupónové kampane",
    "campaigns.qr.header": "Zoznam kupónových kampaní",
    "campaigns.coupons.header": "Zoznam nepriradených sád kupónov",
    "campaigns.detail.basicInfo.title": "Základné informácie",
    "campaigns.detail.budget.title": "Rozpočet",
    "campaigns.detail.spots.title": "Spoty kampane",
    "campaigns.detail.schedule.title": "Časový rozvrh",
    "campaigns.detail.stores.title": "Lokalita a prevádzky",
    "campaigns.detail.company.title": "Spoločnosť",
    "campaigns.detail.map.title": "Mapa prevádzok",
    "campaigns.budgetCard.title": "Financovanie kampane",
    "campaigns.budgetCard.budgetTotal": "Maximálny rozpočet kampane",
    "campaigns.budgetCard.budgetSpent": "Spotrebovaný rozpočet",
    "campaigns.budgetCard.budgetRemaining": "Zostávajúci rozpočet",
    "campaigns.budgetCard.AvgBid": "Priemerná cena za prehratie spotu",
    "campaigns.budgetCard.MaxBid": "Maximálna cena za prehratie spotu",
    "campaigns.budgetCard.balanceOfCredits": "Zostatok kreditov: ",
    "campaigns.budgetCard.lackOfCredits": "Zostatok: Spoločnosť nemá dostatočný počet kreditov",
    "campaigns.spotsCard.title": "Spoty kampane",
    "campaigns.spotsCard.count": "prehratí",
    "campaigns.companyCard.title": "Spoločnosť",
    "campaigns.companyCard.name": "Názov",
    "campaigns.companyCard.address": "Adresa",
    "campaigns.companyCard.contact": "Kontakt",
    "campaigns.companyCard.phone": "Telefón",
    "campaigns.companyCard.email": "E-mail",
    "campaigns.companyCard.details.button": "Detail spoločnosti",
    "campaigns.infoCard.title": "Základné informácie",
    "campaigns.infoCard.owner": "Objednávateľ kampane",
    "campaigns.infoCard.description": "Popis kampane",
    "campaigns.infoCard.startDate": "Začiatok kampane",
    "campaigns.infoCard.endDate": "Koniec kampane",
    "campaigns.scheduleCard.title": "Časový rozvrh",
    "campaigns.scheduleCard.repeat": "Perióda opakovania (min)",
    "campaigns.mapCard.title": "Mapa prevádzok",
    "campaigns.targetingCard.title": "Lokalita a prevádzky",
    "campaigns.targetingCard.placeholder.location": "Lokalita",
    "campaigns.targetingCard.placeholder.category": "Typ prevádzky",
    "campaigns.targetingCard.placeholder.company": "Spoločnosť",
    "campaigns.targetingCard.placeholder.store": "Prevádzka",
    "campaigns.delete.response.success": "Kampaň bola úspešne odstránená",
    "campaigns.cancel.response.success": "Kampaň bola úspešne ukončená",
    "campaigns.delete.response.error": "Kampaň nebola odstránená",
    "campaigns.new.createButton": "Vytvoriť kampaň",
    "campaigns.new.cancelButton": "Zrušiť",
    "campaigns.new.freeFoto.title": "Nová Free Foto kampaň",
    "campaigns.new.qr.title": "Nová kupónová kampaň",
    "campaigns.submit": "Submit",
    "campaigns.status.running": "Spustená",
    "campaigns.status.awaiting": "Spracováva sa",
    "campaigns.status.rejected": "Zamietnutá",
    "campaigns.status.finished": "Ukončená",
    "campaigns.status.scheduled": "Naplánovaná",
    "campaigns.status.inactive": "Neaktívna",
    "campaigns.table.actions": "Akcie",
    "campaigns.table.ID": "ID",
    "campaigns.table.name": "Názov",
    "campaigns.table.owner": "Zadávateľ",
    "campaigns.table.type": "Typ kampane",
    "campaigns.table.status": "Stav",
    "campaigns.table.start": "Začiatok",
    "campaigns.table.end": "Koniec",
    "campaigns.table.maxPrice": "Max. cena",
    "campaigns.table.budget": "Rozpočet",
    "campaigns.table.cupons": "Kupóny",
    "campaigns.table.couponCount": "Počet kupónov",
    "campaigns.table.couponNominalValue": "Nom. hodnota",
    "campaigns.table.author": "Vytvoril",
    "campaigns.table.updatedAt": "Dátum a čas",
    "campaigns.table.totalSetValue": "Objem",
    "campaigns.newCoupons.nominalValue": "Nominálna hodnota (C)",
    "campaigns.newCoupons.title": "Vytvoriť sadu kupónov",
    "campaigns.newCoupons.create": "Vytvoriť",
    "campaigns.newCoupons.success": "Sada kupónov bola vytvorená",
    "campaigns.button.newCampaign": "Nová kampaň",
    "campaigns.button.newCouponSet": "Nová sada kupónov",
    "campaigns.toggle.couponSet": "Sady kupónov",
    "campaigns.toggle.qrCampaign": "Kupónové kampane",
    "campaigns.approved.response.success": "Kampaň bola schválená",
    "campaigns.rejected.response.success": "Kampaň bola zamietnutá",
    "campaigns.approved.button": "Schváliť kampaň",
    "campaigns.rejected.button": "Zamietnuť kampaň",
    "campaigns.removeModal.text": "Naozaj chcete ukončiť kampaň",
    "campaigns.rejectedModal.text": "Naozaj chcete zamietnuť kampaň",
    "campaigns.approvedModal.text": "Naozaj chcete schváliť kampaň",
    "campaigns.pictureCard.title": "Náhľad",
    "campaigns.creditChart.title": "Náklady",
    "campaigns.creditChart.remainingCredits": "Zostávajúce kredity",
    "campaigns.creditChart.usedCredits": "Použité kredity",
    "campaigns.photosChart.title": "Výtlačky",
    "campaigns.photosChart.remainingPhotos": "Zostávajúce",
    "campaigns.photosChart.usedPhotos": "Vytlačené",
    "campaigns.photosChart.allocatedPhotos": "Alokované",
    "campaigns.infoCard.name": "Názov kampane",
    "campaigns.infoCard.company": "Objednávateľ kampane",
    "campaigns.infoCard.createdBy": "Vytvoril:",
    "campaigns.infoCard.createdAt": "Dátum a čas:",
    "campaigns.budgetCard.account": "Účet:",
    "campaigns.budgetCard.qrBudget": "Počet kreditov:",
    "campaigns.budgetCard.maxPhotos": "Max. výtlačkov:",
    "campaigns.budgetCard.freeFotoBudget": "Počet výtlačkov:",
    "campaigns.coupons.removeWarning": "Naozaj chcete odstrániť sadu kupónov?",
    "campaigns.coupons.removeButton": "Odstrániť",
    "campaigns.coupons.removeSuccess": "Sada kupónov bola odstránená",
    "campaigns.coupons.couponSetExport": "Sada kupónov bola exportovaná do CSV súboru",
    "campaigns.graficCard.errorResolution": "Zvolili ste obrázok s nesprávnym rozlíšením",
    "campaigns.graficCard.successUpload": "Reklamný banner bol úspešne načítaný",
    "campaigns.graficCard.title": "Grafika kampane: ",
    "campaigns.graficCard.rules": "Pravidlá: ",
    "campaigns.graficCard.changeRules": "Vybrať iný obrázok: ",
    "campaigns.graficCard.firstRule": "1.) Rozlíšenie reklamného baneru: 600 x 1200 px",
    "campaigns.graficCard.secondRule": "2.) Podporované formáty: .jpg, .png",
    "campaigns.graficCard.choosePicture": "Pre výber obrázka stlačte tlačidlo: ",
    "campaigns.graficCard.changePicture": "Pre zmenu obrázka stlačte tlačidlo: ",
    "campaign.new.successCreated": "Kampaň bola vytvorená",
    "campaigns.setCouponSet.button": "Priradiť kupóny",
    "campaigns.setCouponSet.assigned": "Priradiť",
    "campaigns.setCouponSet.label": "Sada nepriradených kupónov",
    "campaigns.setCouponSet.placeholder": "Vyberte sadu kupónov...",
    "campaigns.setCouponSet.success": "Sada kupónov bola priradená",
    "campaigns.setCouponSet.confirm": "Naozaj chcete priradiť do kampane túto sadu kupónov?",
    "campaigns.setCouponSet.choosenCouponSet": "Zvolená sada kupónov:",
    "campaigns.setCouponSet.credits": "Zostatok kreditov:",
    "campaigns.couponsChart.title": "Kupóny",
    "campaigns.couponsChart.remainingCoupons": "Nepoužité kupóny",
    "campaigns.couponsChart.usedCoupons": "Použité kupóny",
    "campaigns.couponsChart.allCoupons": "Všetky kupóny",
    "campaigns.couponsChart.remainingBudget": "Voľný kredit",
    "campaigns.couponsChart.allocatedBudget": "Alokovaný kredit",
    "campaigns.couponsChart.allBudget": "Celkový rozpočet",
    "campaigns.couponsChart.usedBudget": "Použitý kredit",
    "campaigns.couponsChart.unusedBudget": "Nepoužitý kredit",
    "campaigns.couponsChart.noCoupons": "Nie je priradená žiadna sada kupónov",
    "campaigns.couponsChart.noAllocated": "Nie sú použíté ani alokované žiadne kredity",
    "campaigns.couponsTable.title": "Zoznam kupónov",
    "campaigns.couponWidget.title": "Sady kupónov",
    "campaigns.table.couponIdDetail": "ID",
    "campaigns.table.couponStatusDetail": "Stav",
    "campaigns.table.couponNominalValueDetail": "Hodnota",
    "campaigns.table.couponCountDetail": "Počet",
    "campaigns.table.couponUsedDetail": "Minuté",
    "campaigns.table.totalSetValueDetail": "Kredit",
    "campaigns.table.couponUsedCreditsDetail": "Minuté k.",
    "campaigns.table.detailButton": "Detail",
    "campaigns.table.exportButton": "Export",
    "campaigns.table.couponDate": "Využité",
    "campaigns.table.couponUser": "Používateľ",
    "campaigns.table.couponToken": "Token",
    "campaigns.table.coupon.active": "Akívna",
    "campaigns.table.coupon.depleted": "Vyčerpaná",
    "campaigns.table.coupon.used": "Použitý",
    "campaigns.table.coupon.unused": "Nepoužitý",
    "campaigns.couponSetDetail.title": "Kupónová sada",
    "campaigns.couponSetDetail.id": "ID Sady:",
    "campaigns.couponSetInfo.couponsNumber": "Počet kupónov",
    "campaigns.couponSetInfo.couponsNominalValue": "Nominálna hodnota",
    "campaigns.couponSetInfo.couponsTotalValue": "Celková hodnota",
    "campaigns.couponSetUse.title": "Využitie",
    "campaigns.couponSetUse.couponsAllNumber": "Počet kupónov",
    "campaigns.couponSetUse.couponsUsedNumber": "Počet použitých kupónov",
    "campaigns.couponSetUse.couponsRemainigNumber": "Počet nepoužitých kupónov",
    "campaigns.couponSetUse.couponsAllCredits": "Celková hodnota sady",
    "campaigns.couponSetUse.couponsUsedCredits": "Použitý kredit",
    "campaigns.couponSetUse.couponsRemainingCredits": "Nepoužitý kredit",
    "campaigns.couponSetInfo.table": "Kupóny",
    "campaigns.couponSetDetail.exportButton": "Export",
    "campaigns.unassignedCoupons.couponSet": "Sada",
    "campaigns.unassignedCoupons.value": "Hodnota",
    'campaigns.companyCard.groups': 'Skupiny'
}

const companies = {
    "companyList.title": "Spoločnosti",
    "companyList.table.title": "Zoznam spoločností",
    "companyList.table.allCompanies": "Zoznam spoločností",
    "companyList.table.addCompany": "Vytvoriť spoločnosť",
    "companyList.assignDevice": "VYTVORIŤ SPOLOČNOSŤ",
    "companyList.table.active": "Aktívny",
    "companyList.table.changePartnerSuccess": "Zmena partnera úspešná",
    "companyList.table.changePartnerFailed": "Zmena partnera neúspešná",
    "companyList.table.ID": "ID",
    "companyList.table.name": "Názov",
    "companyList.table.category": "Kategória",
    "companyList.table.stores": "Prevádzky",
    "companyList.table.address": "Adresa",
    "companyList.table.state": "Stav",
    "companyList.table.partner": "Partner",
    "companyList.table.company": "Spoločnosť",
    "companyList.table.placement": "Umiestnenie",
    "companyList.table.actions": "Akcie",
    "companyList.table.actions.detail": "Detail",
    "companyList.enabledToggle": "Prevádzka",
    "companyList.disabledToggle": "Sklad",
    "companyList.addModal.title": "VYTVORIŤ SPOLOČNOSŤ",
    "companyList.addModal.add": "PRIDAŤ",
    "companyList.addModal.cancel": "ZRUŠIŤ",
    "companyList.assignModal.partner": "Partner",
    "companyList.assignModal.company": "Vyberte spoločnosť...",
    "companiesList.filter.active": "Aktívny",
    "companiesList.filter.nonActive": "Neaktívny",
    "company.partner.assign.success": "Partner bol úspešne priradený",
    "company.partner.remove.success": "Partner bol odobratý",
    "company.store.assign.success": "Prevádzka bola úspešne priradená",
    "companyList.addCompany.title": "Nová spoločnosť",
    "companyDetail.title": "Spoločnosť",
    "companyDetail.status.storeState": "Stav spoločnosti",
    "companyDetail.status.restart": "RESTART",
    "companyDetail.status.deactivate": "DEACTIVATE",
    "companyDetail.status.reportError": "Report error",
    "companyDetail.status.activate": "Activate",
    "companyDetail.status.back": "Back",
    "companyDetail.status.restartModal.title": "Restart company",
    "companyDetail.status.restartModal.text":
        "Do you want to restart the company?",
    "companyDetail.status.deactivateModal.title": "Deactivate company",
    "companyDetail.status.deactivateModal.text":
        "Do you want to deactivate the company?",
    "companyDetail.status.activateModal.title": "Activate company",
    "companyDetail.status.activateModal.text":
        "Do you want to activate the company?",
    "companyDetail.partner.title": "Partner",
    "companyDetail.partner.printerPhotos": "Printer photo count",
    "companyDetail.partner.orderPhotos": "Order photo count",
    "companyDetail.partner.changePartner": "CHANGE PARTNER",
    "companyDetail.partner.removePartner": "REMOVE PARTNER",
    "companyDetail.partner.detailPartner": "PARTNER DETAIL",
    "companyDetail.partner.changePartnerModal.title": "Change partner",
    "companyDetail.partner.changePartnerModal.action": "CHANGE",
    "companyDetail.partner.removePartnerModal.title": "Remove partner",
    "companyDetail.partner.removePartnerModal.text":
        "Do you want to remove the partner?",
    "companyDetail.partner.removePartnerModal.action": "REMOVE",
    "companyDetail.partner.back": "Back",
    "companyDetail.orders.title": "Orders",
    "companyDetail.orders.ordersList": "Order list",
    "companyDetail.orders.table.id": "ID",
    "companyDetail.orders.table.date": "Date",
    "companyDetail.orders.table.volume": "Amount",
    "companyDetail.orders.table.actions": "Actions",
    "companyDetail.orders.table.actions.detail": "Detail",
    "companyDetail.provisions.title": "Commissions",
    "companyDetail.provisions.totalProvision": "Total commission",
    "companyDetail.provisions.provisionsList": "Commission list",
    "companyDetail.address.placeholder": "Enter address",
    "companyDetail.address.undefinedLocation": "Location not specified",
    "companyDetail.partner.removePartnerSuccessful": "Partner removed successfuly",
    "companyDetail.partner.removePartnerFailed": "Failed to remove partner",
    "companyDetail.partner.changePartnerSuccessful": "Partner changed  successfuly",
    "companyDetail.partner.changePartnerFailedl": "Failed to change partner",
    "companyDetail.status.companyDeactivationSuccessful":
        "Deactivated successfuly",
    "companyDetail.status.companyActivationSuccessful": "Activated successfuly",
    "companyDetail.status.companyDeactivationFailed": "Failed to deactivate",
    "companyDetail.status.companyActivationFailed": "Failed to activate",
    "companyDetail.status.companyRestartSuccessful": "Restarted successfuly",
    "companyDetail.status.companyRestartFailed": "Failed to restart",
    "companyDetail.partner.changeAddressFailed": "Failed to change address",
    "companyDetail.partner.changeAddressSuccessful": "Address changed successfuly",
    "companyDetail.address.title": "Adresa",
    "companyDetail.partnersTable.title": "Zoznam partnerov",
    "companyDetail.partnersTable.id": "ID",
    "companyDetail.partnersTable.name": "Name",
    "companyDetail.partnersTable.action": "Action",
    "companyDetail.addressCard.name": "Názov spoločnosti",
    "companyDetail.addressCard.address": "Ulica a číslo",
    "companyDetail.addressCard.postal": "PSČ",
    "companyDetail.addressCard.city": "Mesto",
    "companyDetail.addressCard.country": "Štát",
    "companyDetail.contactPersonCard.title": "Kontaktná osoba",
    "companyDetail.contactPersonCard.firstName": "Meno",
    "companyDetail.contactPersonCard.lastName": "Priezvisko",
    "companyDetail.contactPersonCard.phone": "Telefón (Formát: +421903123123)",
    "companyDetail.contactPersonCard.email": "E-mail",
    "companyDetail.billingInfoCard.ico": "IČO",
    "companyDetail.billingInfoCard.dic": "DIČ",
    "companyDetail.billingInfoCard.icDPH": "IČ DPH",
    "companyDetail.billingInfoCard.registrationCourt": "Registrový súd, vložka",
    "companyDetail.billingInfoCard.iban": "IBAN",
    "companyDetail.billingInfoCard.bankBIC": "BIC/SWIFT",
    "companyDetail.billingInfoCard.bankName": "Názov banky",
    "companyDetail.accountStatus.title": "Stav účtu",
    "companyDetail.accountStatus.status": "Zostatok na učte: ",
    "companyDetail.accountStatus.stores": "Prevádzky",
    "companyDetail.accountStatus.commissions": "Provízie",
    "companyDetail.btn.assignPartner": "Priradiť partnera",
    "companyDetail.btn.assign": "PRIRADIŤ",
    "companyDetail.btn.assignStore": "Priradiť prevádzku",
    "companyDetail.btn.removeCompany": "Odstrániť spoločnosť",
    "companyDetail.btn.remove": "ODSTRÁNIŤ",
    "companyDetail.store.title": "Prevádzka",
    "companyDetail.company.removeCompanySuccessful":
        "Spoločnosť bola úspešne odstránená",
    'companyDetail.company.updateCompanySuccessful': "Detaily spoločnosti boli úspešne aktualizované",
};

const stores = {
    "storeList.title": "Prevádzky",
    "storeList.table.title": "Zoznam prevádzok",
    "storeList.table.active": "Aktívny",
    "storeList.table.changePartnerSuccess": "Zmena partnera úspešná",
    "storeList.table.changePartnerFailed": "Zmena partnera neúspešná",
    "storeList.table.ID": "ID",
    "storeList.table.name": "Názov",
    "storeList.table.partnerName": "Meno",
    "storeList.table.zones": "Zóny",
    'storeList.table.segment': "Segmenty",
    "storeList.table.address": "Adresa",
    "storeList.table.state": "Stav prehrávača",
    "storeList.table.heartbeat": "Heartbeat",
    "storeList.table.partner": "Partner",
    "storeList.table.company": "Spoločnosť",
    "storeList.table.deviceStatus": "Prehrávače",
    "storeList.table.playerStatus": "Video",
    "storeList.table.printedPhotos": "Počet výtlačkov",
    "storeList.table.placement": "Umiestnenie",
    "storeList.table.actions": "Akcie",
    "storeList.table.actions.detail": "Detail",
    "storeList.table.actions.remove": "Odobrať",
    'storeList.table.actions.removeWarning': "Chystáte sa odobrať partnera spoločnosti",
    "storeList.enabledToggle": "Prevádzka",
    "storeList.disabledToggle": "Sklad",
    "storeList.assignModal.title": "Pridať prevádzku",
    "storeList.assignModal.cancel": "ZRUŠIŤ",
    "storeList.assignModal.partner": "Partner",
    "storeList.assignModal.store": "Prevádzka",
    "storeList.addModal.add": "PRIDAŤ",
    "storeList.addModal.title": "Vytvoriť prevádzku",
    "devicesList.filter.active": "Aktívny",
    "devicesList.filter.nonActive": "Neaktívny",
    "storeList.addStore.title": "Nová prevádzka",
    "storeList.addStore.success": "Prevádzka {0} bola vytvorená",
    "storeList.operator.name": "Meno prevádzkara",
    "storeList.partner.name": "Meno partnera",
    "storeList.company.name": "Názov spoločnosti",
    "storeList.table.addStore": "Vytvoriť prevádzku",
    "storeList.table.allStore": "Zoznam prevádzok",
    "storeList.online": "Online",
    "storeList.offline": "Offline",
    "storeList.playing": "Prehráva sa",
    "storeList.stopped": "Neprehráva sa",
    "storeList.partial": "Čiastočne",
    "storeList.unassigned": "Bez prehrávača",
    "storeList.withoutDevice": "Bez Prehrávača",
    "storeList.error": "Chyba",
    "storeDetail.title": "Prevádzka",
    "storeDetail.titleName": "Názov prevádzky",
    "storeDetail.category": "Kategória",
    "storeDetail.area": "Plocha prevádzky(m2)",
    "storeDetail.status.storeState": "Stav prevádzky",
    "storeDetail.partner.title": "Partner",
    "storeDetail.partner.changePartner": "CHANGE PARTNER",
    "storeDetail.partner.removePartner": "REMOVE PARTNER",
    "storeDetail.partner.detailPartner": "PARTNER DETAIL",
    "storeDetail.partner.changePartnerModal.title": "Change partner",
    "storeDetail.partner.changePartnerModal.action": "CHANGE",
    "storeDetail.partner.removePartnerModal.title": "Remove partner",
    "storeDetail.partner.removePartnerModal.text": "Do you want to remove the partner?",
    "storeDetail.partner.removePartnerModal.action": "REMOVE",
    "storeDetail.partner.back": "Back",
    "storeDetail.orders.table.id": "ID",
    "storeDetail.orders.table.date": "Date",
    "storeDetail.orders.table.volume": "Amount",
    "storeDetail.orders.table.actions": "Actions",
    "storeDetail.orders.table.actions.detail": "Detail",
    "storeDetail.address.title": "Location address",
    "storeDetail.address.placeholder": "Enter address",
    "storeDetail.address.undefinedLocation": "Location not specified",
    "storeDetail.partner.removePartnerSuccessful": "Partner removed successfuly",
    "storeDetail.partner.removePartnerFailed": "Failed to remove partner",
    "storeDetail.store.addstoreSuccessful": "Prevádzka úspešne pridaná",
    "storeDetail.store.updatedSuccessful": "Prevádzka úspešne aktualizovaná",
    "storeDetail.partner.changePartnerFailedl": "Failed to change partner",
    "storeDetail.status.storeDeactivationSuccessful": "Deactivated successfuly",
    "storeDetail.status.storeActivationSuccessful": "Activated successfuly",
    "storeDetail.status.storeDeactivationFailed": "Failed to deactivate",
    "storeDetail.status.storeActivationFailed": "Failed to activate",
    "storeDetail.status.storeRestartSuccessful": "Restarted successfuly",
    "storeDetail.status.storeRestartFailed": "Failed to restart",
    "storeDetail.partner.changeAddressFailed": "Failed to change address",
    "storeDetail.partner.changeAddressSuccessful": "Address changed successfuly",
    "storeDetail.openingHours.title": "Otváracie hodiny",
    "storeDetail.openingHours.holiday": "Otvorené cez sviatky",
    "storeDetail.openingHours.specialClosed": "Výnimočne zatvorené",
    'storeDetail.openingHours.open': 'Otvorené',
    'storeDetail.openingHours.closed': 'Zatvorené',
    "storeDetail.playtime.open": "Začiatok prehrávania po otvorení",
    "storeDetail.playtime.close": "Koniec prehrávania pred zatvorením",
    "storeDetail.countAdSpots": "Počet reklamných spotov",
    "storeDetail.storeCard.name": "Názov prevádzky",
    "storeDetail.storeCard.address": "Adresa prevádzky",
    "storeDetail.storeCard.category": "Kategória",
    "storeDetail.storeCard.area": "Plocha prevádzky (m2)",
    "storeDetail.storeCard.visitRate": "Návštevnosť (osôb/hod.)",
    "storeDetail.companyCard.title": "Spoločnosť",
    "storeDetail.companyCard.name": "Názov spoločnosti",
    "storeDetail.companyCard.address": "Adresa spoločnosti",
    "storeDetail.companyCard.contactPerson": "Meno kontaktnej osoby",
    "storeDetail.companyCard.phone": "Telefón (Formát: +421903123123)",
    "storeDetail.companyCard.email": "E-mail",
    "storeDetail.storeAddressCard.title": "Adresa",
    "storeDetail.storeAddressCard.address": "Ulica a popisné číslo",
    "storeDetail.storeAddressCard.postal": "PSČ",
    "storeDetail.storeAddressCard.city": "Mesto",
    "storeDetail.storeAddressCard.cityPlaceholder": "Vyberte mesto...",
    "storeDetail.storeAddressCard.country": "Štát",
    "storeDetail.storeAddressCard.latitude": "Zemepisná šírka",
    "storeDetail.storeAddressCard.longtitude": "Zemepisná dĺžka",
    "storeDetail.partnerCard.firstName": "Meno",
    "storeDetail.partnerCard.lastName": "Priezvisko",
    "storeDetail.partnerCard.partnerAddress": "Adresa partnera",
    "storeDetail.partnerCard.phone": "Telefón (Formát: +421903123123)",
    "storeDetail.partnerCard.email": "E-mail",
    "storeDetail.partnerCard.addPartner": "Vytvoriť partnera",
    "storeDetail.partnerCard.allPartners": "Všetci partneri",
    "storeDetail.partnerCard.allOrders": "Zoznam objednávok",
    "storeDetail.partnerCard.allPayments": "Zoznam platieb",
    "storeDetail.maintainer.title": "Prevádzkar",
    "storeDetail.removeStore.text": "Naozaj chcete odstrániť prevádzku",
    "storeDetail.contactPerson.title": "Kontaktná osoba",
    "storeDetail.billing.title": "Fakturačné údaje",
    "storeDetail.removeStore.title": "Odstrániť prevádzku",
    "storeDetail.removePlayer.title": "Odobrať prehrávač",
    "storeDetail.removePlayer.successful": "Prehrávač bol úspešne odobratý",
    "storeDetail.removeStore.successful": "Prevádzka bola úspešne odstránená",
    "storeDetail.updateStore.successful": "Prevádzka bola úspešne aktualizovaná",
    "storeDetail.changePlayer.text-1": "K prevádzke ",
    "storeDetail.changePlayer.text-2": "je priradený prehrávač",
    "storeDetail.changePlayer.text-3": "Vyberte nový prehrávač",
    "storeDetail.mediaCard.fileName": "Názov súboru",
    "storeDetail.maintainerCard.title": "Prevádzkar",
    "storeDetail.maintainerCard.firstName": "Meno",
    "storeDetail.maintainerCard.lastName": "Priezvisko",
    "storeDetail.maintainerCard.phone": "Telefón (Formát: +421903123123)",
    "storeDetail.maintainerCard.email": "E-mail",
    "storeDetail.device.remove": "Odobrať",
    "storeDetail.commissionCard.allCommissions": "Celková výška provízií",
    "storeDetail.commissionCard.button": "Zoznam provízií ",
    "storeDetail.commissionCard.title": "Provízie",
    "storeDetail.commissionCard.com": "Výška provízií",
    "storeDetail.printedPhotosCard.title": "Počet vytlačených fotografií za posledných 30 dní (dummy)",
    "storeDetail.printedPhotosCard.allPhotos": "Spolu",
    "storeDetail.printedPhotosCard.threshold": "Hranica provízií",
    "storeDetail.printedPhotosCard.photos": "Vytlačené fotografie",
    "storeDetail.printedPhotosCard.day": "Deň",
    "storeDetail.deviceStatusCard.title": "Stav prehrávača",
    "storeDetail.deviceStatusCard.ID": "ID Prehrávača",
    "storeDetail.deviceStatusCard.status": "Stav prehrávača",
    "storeDetail.deviceStatusCard.lastHeartbeat": "Posledný heartbeat",
    "storeDetail.deviceStatusCard.numberOfPhotos": "Zostávajúci počet fotografií",
    "storeDetail.deviceStatusCard.printerCounter": "Počítadlo tlačiarne",
    "storeDetail.deviceStatusCard.averagePhotos": "Priemerný počet fotiek za mesiac",
    "storeDetail.deviceStatusCard.isEnabled": "Stav pripojenia Prehrávača",
    "storeDetail.deviceStatusCard.deviceDetail": "Detail Prehrávača",
    "storeDetail.deviceStatusCard.isEnabled": "Stav pripojenia prehrávača",
    "storeDetail.deviceStatusCard.deviceDetail": "Detail prehrávača",
    "storeDetail.deviceStatusCard.deviceType": "Typ prehrávača",
    "storeDetail.deviceStatusCard.balenaID": "Balena ID",
    "storeDetail.deviceStatusCard.clientVersion": "Verzia prehrávača",
    "storeDetail.deviceInfoCard.title": "Základné info",
    "deviceDetail.success.deviceIdentify": "Na vybranom zariadení sa vám na 5 sekúnd rozsvietila zelená LED kontrolka.",
    "storeDetail.zoneTable.title": "Zoznam zón prevádzky",
    "storeDetail.createZone.title": "Pridať zónu",
    "storeDetail.createZone.name": "Názov zóny",
    "storeDetail.createZone.button": "Pridať",
    "storeDetail.createZone.successful": "Zóna bola úspešne pridaná",
    'storeDetail.statusCard.musicVolume': 'Hlasitosť prehrávania',

    'storeList.playerStatus.tooltip.online': 'Video sa prehráva',
    'storeList.playerStatus.tooltip.offline': 'Video sa neprehráva',
    'storeList.playerStatus.tooltip.partial': 'Na niektorom prehrávači sa video neprehráva',
    'storeList.deviceStatus.tooltip.online': 'Všetky prehrávače sú online',
    'storeList.deviceStatus.tooltip.offline': 'Všetky prehrávače sú offline',
    'storeList.deviceStatus.tooltip.partial': 'Niektorý z prehrávačov je offline',
    'storeList.playerStatus.tooltip.without': 'Prevádzke nie je priradený žiadny prehrávač',
};

const dashboard = {
    "dashboardPage.title": "Prehľad",
    "dashboardCard.campaigns.title": "Kampane",
    "dashboardCard.commercialCampaigns.button": "Komerčné kampane",
    "dashboardCard.internalCampaigns.button": "Interné kampane",
    "dashboardCard.campaigns.allCommercialCampaigns": "Aktívne komerčné kampane:",
    "dashboardCard.campaigns.awaitingCommercialCampaigns": "Komerčné kampane na schválenie:",
    "dashboardCard.campaigns.allInternalCampaigns": "Aktívne interné kampane:",
    "dashboardCard.campaigns.awaitingInternalCampaigns": "Interné kampane na schválenie:",
    "dashboardCard.devices.title": "Prehrávače",
    "dashboardCard.devices.button": "Prehrávače",
    "dashboardCard.devices.allDevices": "Všetky prehrávače:",
    "dashboardCard.devices.devicesOnStores": "V prevádzkach:",
    "dashboardCard.devices.devicesOnRepository": "Na sklade:",
    "dashboardCard.devices.offlineDevices": "Offline:",
    "dashboardCard.companies.title": "Spoločnosti a prevádzky",
    "dashboardCard.companies.button": "Spoločnosti",
    "dashboardCard.companies.allCompanies": "Všetky spoločnosti",
    "dashboardCard.stores.button": "Prevádzky",
    "dashboardCard.stores.allStores": "Všetky prevádzky:",
    "dashboardCard.stores.storesWithoutDevice": "Bez prehrávača:",
    "dashboardCard.users.title": "Partneri",
    "dashboardCard.users.button": "Partneri",
    "dashboardCard.users.allUsers": "Všetci:",
    "dashboardCard.users.activatedUsers": "Aktívni:",
    "dashboardCard.users.unactivatedUsers": "Neaktívni:",
    "dashboardCard.users.blockedUsers": "Blokovaní:",
    "dashboardCard.media.title": "Médiá",
    "dashboardCard.streams.button": "Streamy",
    "dashboardCard.streams.allStreams": "Všetky streamy:",
    "dashboardCard.streams.mostListenedStream": "Najpočúvanejší stream:",
    "dashboardCard.media.button": "Médiá",
    "dashboardCard.media.allMedia": "Všetky média v knižnici:",
    "dashboardCard.media.awaitingMedia": "Médiá na schválenie:",
    "dashboardCard.invoices.title": "Faktúry a dobropisy",
    "dashboardCard.invoices.button": "Faktúry",
    "dashboardCard.invoices.allInvoices": "Všetky faktúry:",
    "dashboardCard.invoices.invoicesVolume": "Objem faktúr:",
    "dashboardCard.payouts.button": "Dobropisy",
    "dashboardCard.payouts.allPayouts": "Všetky dobropisy:",
    "dashboardCard.payouts.payoutsVolume": "Objem dobropisov:",
    "dashboardCard.commissions.title": "Provízie",
    "dashboardCard.commissions.button": "Provízie",
    "dashboardCard.commissions.allCommissions": "Celková provízia:",
    'dashboardCard.playlist.button': 'Playlisty',
    "dashboardCard.playlist.title": "Playlisty",
    "dashboardCard.playlist.allPlaylist": "Všetky playlisty:",
    "dashboardCard.playlist.awaitingPlaylists": "Nepriradené playlisty:"

};

const zones = {
    'zoneList.table.device': 'Prehrávač',
    'zoneDetail.title': 'Zóna',
    'zoneDetail.basicInfoCard.name': 'Názov zóny',
    'zoneDetail.removeZone.button': 'Odstrániť zónu',
    'zoneDetail.displayCard.title': 'Skupiny displejov',
    'zoneDetail.displayCard.displayGroupA': 'Displej A',
    'zoneDetail.displayCard.displayGroupB': 'Displej B',
    'zoneDetail.displayCard.without': 'Žiadna',
    "zoneDetail.updateZone.successful": "Zóna bola úspešne aktualizovaná",
    "zoneDetail.removeZone.successful": "Zóna bola odstránená",
    'zoneList.playerStatus.tooltip.without': 'Zóne nie je priradený žiadny prehrávač',
}

const orders = {
    "orderList.title": "Objednávky",
    "orderList.table.title": "Zoznam objednávok",
    "orderList.table.ID": "ID",
    "orderList.table.status": "Stav",
    "orderList.table.created": "Vytvorená",
    "orderList.table.notCreated": "Nevytvorená",
    "orderList.table.email": "Email",
    "orderList.table.createdAt": "Vytvorenie",
    "orderList.table.expiration": "Expirácia",
    "orderList.table.credit": "Objem",
    "orderList.table.type": "Typ",
    "orderList.table.creditType": "Kreditná",
    "orderList.table.freeFotoType": "FreeFoto",
    "orderList.table.actions": "Akcie",
    "orderList.table.actions.detail": "Detail",
    "orderDetail.credit.title": "Kreditná objednávka",
    "orderDetail.freeFoto.title": "FreeFoto objednávka",
    "orderDetail.cancelModal.title": "Naozaj chcete stornvať objednávku?",
    "orderDetail.cancelModal.button": "Stornovať",
    "orderDetail.cancel.button": "Stornovať objednávku",
    "orderDetail.statusCard.title": "Základné informácie",
    "orderDetail.statusCard.firstPhotoCount": "Počet fotografií 10x15",
    "orderDetail.statusCard.secondPhotoCount": "Počet fotografií 15x15",
    "orderDetail.statusCard.thirdPhotoCount": "Počet fotografií 20x15",
    "orderDetail.statusCard.totalCount": "Celkový počet fotografií",
    "orderDetail.statusCard.totalCredits": "Hodnota objednávky",
    "orderDetail.customerCard.title": "Objednávateľ",
    "orderDetail.customerCard.name": "Meno a Priezvisko",
    "orderDetail.customerCard.email": "E-mail",
    "orderDetail.customerCard.phone": "Telefón",
    "orderDetail.customerCard.userDetail": "Detail používateľa",
    "orderDetail.historyCard.title": "Historia objednávky",
    "orderDetail.historyCard.Created": "Vytvorená",
    "orderDetail.historyCard.Processing": "Spracováva sa",
    "orderDetail.historyCard.Ready": "Čaká na tlač",
    "orderDetail.historyCard.InProgress": "Tlačí sa",
    "orderDetail.historyCard.Completed": "Dokončená",
    "orderDetail.historyCard.Incomplete": "Nedokončená",
    "orderDetail.historyCard.Partial": "Čiatočne dokončená",
    "orderDetail.historyCard.Expired": "Expirovaná",
    "orderDetail.historyCard.Canceled": "Stornovaná",
    "orderDetail.removeOrder.successful": "Objednávka bola stornovaná"
}


const displays = {
    "displayList.title": "Skupiny displejov",
    "displayList.table.title": "Zoznam skupín displejov",
    "displayList.table.ID": "ID",
    "displayList.table.name": "Názov",
    "displayList.table.playlist": "Playlist",
    "displayList.table.company": "Spoločnosť",
    "displayList.table.description": "Popis",
    "displayList.table.actions": "Akcie playlisty",
    "displayList.table.actions.assign": "Priradiť",
    "displayList.table.actions.change": "Zmeniť",
    "displayList.table.actions.delete": "Odobrať",
    "displayList.modal.title": "Priradiť playlist vybranej skupine displejov",
    "displayList.modal.playlist": "Playlist",
    "displayList.modal.confirmButton": "Priradiť",
    "displayList.modal.cancelButton": "Zrušiť",
    "displayList.table.actions.delete": "Odobrať",
    "displayList.deleteModal.title": "Chystáte sa odobrať playlist skupine displejov. Chcete pokračovať?",
    'displayList.assignPlaylist.success': "Operácia prebehla úspešne"
}


const invoices = {
    "invoicesList.title": "Faktúry",
    "invoicesList.enabledToggle": "Faktúry",
    "invoicesList.disabledToggle": "Dobropisy",
    "invoicesList.table.title": "Faktúry a dobropisy",
    "invoicesList.table.ID": "ID",
    "invoicesList.table.identifier": "Číslo faktúry",
    "invoicesList.table.company": "Spoločnosť",
    "invoicesList.table.description": "Popis",
    "invoicesList.table.price": "Suma",
    "invoicesList.table.credit": "Kredity",
    "invoicesList.table.date": "Dátum",
    "invoicesList.table.createdBy": "Zaúčtoval",
    "invoicesList.addModal.title": "PÁROVAŤ FAKTÚRU",
    "invoicesList.assignModal.cancel": "ZRUŠIŤ",
    "invoicesList.assignModal.title": "Párovať faktúru",
    "invoicesList.assignModal.company": "Spoločnosť",
    "invoicesList.assignModal.number": "Číslo faktúry",
    "invoicesList.assignModal.price": "Suma (€)",
    "invoicesList.assignModal.credit": "Počet kreditov",
    "invoicesList.assignModal.description": "Popis",
    "invoicesList.assignModal.identifier": "Identifikačné číslo faktúry",
    "invoicesList.assignModal.create": "Vytvoriť",
    "invoicesList.createInvoiceSuccessfull": "Faktúra bola úspešne vytvorená",
    'invoiceDetail.widget.title': "Platba",
    "invoiceDetail.title": "Faktúra",
    'invoiceDetail.price': "Suma platby",
    'invoiceDetail.credit': "Objem kreditov",
    'invoiceDetail.ID': "ID platby",
    'invoiceDetail.identifier': "Číslo platby",
    'invoiceDetail.type': "Typ platby",
    'invoiceDetail.description': "Popis",
    'invoiceDetail.createdBy': "Vytvoril",
    'invoiceDetail.createdAt': "Dátum a čas",
    'invoiceDetail.phone': "Telefón"
};

const payouts = {
    "payoutsList.title": "Dobropisy",
    "payoutsList.table.ID": "ID",
    "payoutsList.table.company": "Spoločnosť",
    "payoutsList.table.description": "Popis",
    "payoutsList.table.price": "Suma",
    "payoutsList.table.credit": "Kredity",
    "payoutsList.table.date": "Dátum",
    "payoutsList.table.createdBy": "Zaúčtoval",
    "payoutsList.addModal.title": "VYTVORIŤ DOBROPIS",
    "payoutsList.assignModal.title": "Vytvoriť dobropis",
    "payoutsList.assignModal.company": "Spoločnosť",
    "payoutsList.assignModal.price": "Suma (€)",
    "payoutsList.assignModal.credit": "Počet kreditov (C)",
    "payoutsList.assignModal.description": "Popis",
    "payoutsList.assignModal.identifier": "Identifikačné číslo dobropisu",
    "payoutsList.assignModal.create": "Vytvoriť",
    "payoutsList.createPayoutSuccessfull": "Dobropis bol úspešne vytvorený",
    "payoutDetail.title": "Dobropis",

};

const operations = {
    "operationsList.title": "Operácie",
    "operationsList.table.title": "Zoznam operácií",
    "operationsList.table.ID": "ID",
    "operationsList.table.date": "Dátum",
    "operationsList.table.sourceID": "ID zdroja",
    "operationsList.table.operation": "Typ operácie",
    "operationsList.table.destinationID": "ID cieľa",
    "operationsList.table.price": "Hodnota",
};

const payments = {
    "paymentsList.title": "Platby",
    "paymentsList.table.title": "Zoznam platieb",
    "paymentsList.table.ID": "ID",
    "paymentsList.table.type": "Typ",
    "paymentsList.table.value": "Objem",
    "paymentsList.table.price": "Suma",
    "paymentsList.table.date": "Dátum",
    "paymentsList.table.purpose": "Účel",
    "paymentsList.table.user": "Používateľ",
    "paymentsList.table.operationID": "Operácia",
    "paymentsList.table.payoutID": "ID platby",
    "paymentsList.table.type.GooglePay": "Google Pay",
    "paymentsList.table.type.ApplePay": "Apple Pay",
    "paymentsList.table.type.CreditCard": "platba kartou",
    "paymentsList.table.purpose.OrderPayment": "platba objednávky",
    "paymentsList.table.purpose.Subscription": "predplatné"
};

const commissions = {
    "commissionsList.title": "Provízie",
    "commissionsList.table.title": "Zoznam provízií",
    "commissionsList.addModal.title": "EXPORT",
    'commissionsList.table.commissionID': "ID Provízie",
    'commissionsList.table.companyID': "ID Spoločn.",
    'commissionsList.table.companyName': "Spoločnosť",
    'commissionsList.table.stores': "Prevádzky",
    "commissionsList.table.email": "Email",
    "commissionsList.table.date": "Obdobie",
    "commissionsList.table.credit": "Objem",
    "commissionsList.table.margin": "Sadzba",
    "commissionsList.table.price": "Suma",
    "commissionsList.table.actions": "Akcie",
    'commissionsList.table.storeID': "ID Prevádzky",
    'commissionsList.table.storeName': "Prevádzka",
    "commissionsList.table.address": "Umiestnenie",
    "commissionList.table.subtitle": "Celkom",
    "commissionsList.assignModal.title": "Exportovať dáta",
    "commissionsList.assignModal.pickerStart": "Mesiac od: ",
    "commissionsList.assignModal.pickerEnd": "Mesiac do: ",
    "commissionsList.assignModal.monthFrom": "Zvoľte počiatočný mesiac",
    "commissionsList.assignModal.monthTo": "Zvoľte koncový mesiac",
    "commissionsList.table.pickerStart": "Od: ",
    "commissionsList.table.pickerEnd": "Do: ",
    "commissionsList.month.january": "Január",
    "commissionsList.month.february": "Február",
    "commissionsList.month.march": "Marec",
    "commissionsList.month.april": "Apríl",
    "commissionsList.month.may": "Máj",
    "commissionsList.month.jun": "Jún",
    "commissionsList.month.july": "Júl",
    "commissionsList.month.august": "August",
    "commissionsList.month.september": "September",
    "commissionsList.month.october": "Október",
    "commissionsList.month.november": "November",
    "commissionsList.month.december": "December",
    "commissionsList.export.success": "Provízie boli úspešne exportované do csv súboru",
    "commissionDetail.title": "Provízie spoločnosti",
    "commissionDetail.photo.title": "Fotografie",
    "commissionDetail.photo.body": "Celkový počet fotografií",
    "commissionDetail.commission.title": "Provízia",
    "commissionDetail.commission.body": "Celková výška provízie",
    "commissionDetail.margin.title": "Sadzba",
    "commissionDetail.margin.body": "Priemenerná percentuálna sadzba",
};

const profilePage = {
    'profile.contactInfo.title': 'Kontaktné Informácie',
    'profile.contactInfo.firstName': 'Meno',
    'profile.contactInfo.lastName': 'Priezvisko',
    'profile.contactInfo.gender': 'Pohlavie',
    'profile.contactInfo.email': 'E-mail',
    'profile.contactData.title': 'Kontaktné údaje',
    'profile.contactData.phone': 'Telefón',
    'profile.contactData.email': 'Email',
    'profile.bankData.title': 'Bankové informácie',
    'profile.bankData.bic': 'BIC/SWIFT',
    'profile.bankData.iban': 'IBAN',
    'profile.bankData.bankName': 'Banka',
    'profile.changePassword.title': 'Zmena hesla',
    'profile.changePassword.oldPassword': 'Zadajte pôvodné heslo',
    'profile.changePassword.newPassword': 'Zadajte nové heslo',
    'profile.changePassword.verifyPassword': 'Zopakujte nové heslo',
    'profile.changePassword.change': 'ZMENIŤ',
    'profile.identificators.title': 'Identifikátory',
    'profile.identificators.ico': 'IČO',
    'profile.identificators.dic': 'DIČ',
    'profile.identificators.icDph': 'IČ DPH',
    'profile.identificators.registerCode': 'Registrový súd',
    'profile.companyData.title': 'Údaje o spoločnosti',
    'profile.companyData.name': 'Názov  spoločnosti',
    'profile.companyData.city': 'Mesto',
    'profile.companyData.street': 'Ulica',
    'profile.companyData.postal': 'PSČ',
    'profile.companyData.country': 'Krajina',
    'profile.language': 'Jazyk',
    'profile.language.success': 'Language preference was updated',
    'profile.password.empty': 'Nezadali ste žiadne heslo',
    'profile.password.success': 'Heslo bolo úspešene zmenené',
    'profile.password.failed': 'Heslo nebolo zmenené',
    'profile.password.notEquals': 'Heslá sa nezhodujú',
};

const userRoles = {
    'userRoles.partner': 'Partner',
    'userRoles.maintainer': 'Správca',
    'userRoles.advertiser': 'Inzerent',
    'userRoles.user': 'Používateľ',
    'userRoles.admin': 'Administrátor'
};

const deviceStatus = {
    'deviceStatus.offline': 'Offline',
    'deviceStatus.online': 'Online',
    'deviceStatus.error': 'Chyba',
};

const loginPage = {
    'loginPage.cardTitle': 'Prihlásenie',
    'loginPage.email': 'E-mail',
    'loginPage.password': 'Heslo',
    'loginPage.submit': 'Prihlásiť',
    'loginPage.stayLogged': 'Zostať prihlásený',
    'loginPage.forgottenPassword': 'Zabudnuté heslo',
    'loginPage.forgottenPassword.email': 'Váš e-mail',
    'loginPage.forgottenPassword.infoText': 'Uveďte e-mail spojený s Vaším účtom a pošleme Vám odkaz na zmenu hesla.',
    'loginPage.forgottenPassword.cancel': 'Zrušiť',
    'loginPage.forgottenPassword.submit': 'Odoslať',
    'loginPage.forgottenPassword.success': 'Na e-mail bol odoslaný odkaz na zmenu hesla.'
};

const streams = {
    "streams.page.title": "Streamy",
    "streams.page.successRefresh": "Stanice boli úspešne obnovené",
    "streams.page.refreshButton": "Obnoviť stanice",
    "streams.table.title": "Zoznam streamov",
    "streams.table.id": "ID",
    "streams.table.name": "Názov",
    "streams.table.url": "URL",
    "streams.table.state": "Stav",
    "streams.table.actions": "Akcie",
}

const media = {
    "media.page.title": "Zoznam médií",
    "media.page.header": "Knižnica médií",
    "media.page.info": "Tu nahrajte video z lokálneho zariadenia",
    "media.uploadModal.title": "Nahrať súbor",
    "media.uploadModal.cancelButton": "Zrušiť",
    "media.uploadModal.confirmButton": "Nahrať ",
    "media.uploadModal.name": "Názov súboru",
    "media.uploadModal.company": "Spoločnosť",
    "media.uploadModal.chooseCompany": "Vyberte spoločnosť...",
    "media.uploadModal.category": "Kategoria",
    "media.uploadModal.chooseCategory": "Vyberte kategoriu...",
    "media.uploadModal.success": "Nahrávanie súboru prebehlo úspešne",
    "media.table.approved": "Schválené",
    "media.table.pending": "Na schválenie",
    "media.table.rejected": "Zamietnuté",
    "media.table.approvedButton": "Schváliť",
    "media.table.rejectedButton": "Zamietnuť",
    "media.table.deletedButton": "Odstrániť",
    "media.table.approvedModalTitle": "Chcete schváliť tento súbor?",
    "media.table.rejectedModalTitle": "Chcete zamietnuť tento súbor?",
    "media.table.deletedModalTitle": "Chcete odstrániť tento súbor?",
    "media.table.rejectedReason": "Uveďte dôvod zamietnutia",
    "media.table.successApprove": "Súbor bol úspešne schválený",
    "media.table.successReject": "Súbor bol úspešne zamietnutý",
    "media.table.successDelete": "Súbor bol úspešne odstránený",
    "media.table.id": "ID",
    "media.table.date": " Dátum",
    "media.table.status": "Stav",
    "media.table.name": "Názov",
    "media.table.owner": "Vlastník",
    "media.table.category": "Kategória",
    "media.table.duration": "Trvanie",
    "media.table.reason": "Dôvod",
    "media.table.size": "Veľkosť",
    "media.table.actions": "Akcie",
    "media.page.uploadButton": "NAHRAŤ SÚBOR",
    "media.table.category.music": "Hudba",
    "media.table.category.video": "Video",
    "media.table.category.Jingel": "Jingel",
    "media.table.category.CommercialSpot": "Komerčný spot",
    "media.table.category.InternalSpot": "Interný spot",
    "media.table.category.Announcement": "Oznámenie",
    "media.table.category.ButtonAnnouncement": "Tlačidlo",
    "media.table.status.pending": "Čaká sa",
    "media.table.status.uploaded": "Načítaný",
    "media.table.status.processing": "Spracováva sa",
    "media.table.status.processed": "Spracovaný",
    "media.table.status.invalid": "Neplatný",
    "media.table.status.failed": "Chyba",
}

const notificationsPage = {
    'notificationsPage.title': 'Notifikácie',
    'notificationsPage.create': 'VYTVORIŤ NOTIFIKÁCIU',
    'notificationsPage.table.title': 'Zoznam notifikácií',
    'notificationsPage.table.id': 'ID',
    'notificationsPage.table.date': 'Dátum',
    'notificationsPage.table.author': 'Autor',
    'notificationsPage.table.header': 'Hlavička',
    'notificationsPage.table.text': 'Text',
    'notificationsPage.modal.title': 'Nová notifikácia',
    'notificationsPage.modal.header': 'Hlavička',
    'notificationsPage.modal.text': 'Text',
    'notificationsPage.modal.screen': 'Obrazovka',
    'notificationsPage.modal.submit': 'Odoslať',
    'notificationsPage.modal.cancel': 'Zrušiť',
    'notificationsPage.create.success': 'Notifikácia bola odoslaná',
    'notificationsPage.modal.confirm.title': 'Potvrdenie',
    'notificationsPage.modal.confirm.text': 'Chystáte sa odoslať notifikáciu',
    'notificationsPage.modal.confirm.submit': 'ODOSLAŤ',
    'notificationsPage.modal.confirm.cancel': 'NASPÄŤ'
};

const screenTypeEnums = {
    'screenType.Home': 'Domov',
    'screenType.Menu': 'Menu',
    'screenType.Orders': 'Objednávky',
    'screenType.Profile': 'Profil',
    'screenType.Credits': 'Kredity',
    'screenType.Prehrávačs': 'Prehrávače',
};

const userStatuses = {
    'userStatus.activated': 'Aktivované',
    'userStatus.unactivated': 'Neaktivované',
    'userStatus.blocked': 'Blokované',
    'userSubscription.Nothing': 'bez predplatného'
};

const pageHeader = {
    'pageHeader.back': 'Späť'
};

const tableTexts = {
    'tableTexts.rowsPerPage': 'Počet záznamov',
    'tableTexts.of': 'z',
    'tableTexts.moreThan': 'viac ako',
    'tableTexts.piece': ' ks',
    'tableTexts.piece': ' spotov',
    'tableTexts.minutes': ' minút',
    'tableTexts.copyToClipboard': 'Kliknutím skopírujete text',
    'tableTexts.copyToClipboardShort': 'Kliknutím skopírujete',
    'tableTexts.copyToClipboard.success': 'Text bol skopírovaný do schránky'
};

const userDevicesPage = {
    'userDevicesPage.title': 'Prehrávače používateľa {0}'
};

const playlist = {
    'playlistList.title': 'Playlisty',
    'playlistList.table.title': 'Zoznam playlistov',
    'playlistList.table.ID': 'ID',
    'playlistList.table.name': 'Názov',
    'playlistList.table.company': 'Spoločnosť',
    'playlistList.table.media': 'Médiá',
    'playlistList.table.date': 'Dátum',
    'playlistList.table.createdBy': 'Vytvoril',
    'playlistList.table.description': 'Popis',
    'playlistList.table.actions': 'Akcie',
    'playlistList.table.actions.detail': 'Detail',
    'playlistList.button.new': 'Nový playlist',
    'playlist.button.change': 'Uložiť zmeny',
    'playlist.new.title': 'Nový playlist',
    'playlist.basicInfo.title': 'Základné údaje o playliste',
    'playlist.basicInfo.name': 'Názov playlistu',
    'playlist.basicInfo.company': 'Spoločnosť',
    'playlist.basicInfo.description': 'Popis',
    'playlist.media.title': 'Presuňte médiá z knižnice do playlistu',
    'playlist.removePlaylist.button': 'Odstrániť playlist',
    'playlist.modal.title': 'Chystáte sa odstrániť playlist. Chcete pokračovať?',
    'playlist.modal.removeButton': 'Odstrániť',
    'playlist.save.success': 'Playlist bol aktualizovaný',
    "playlist.deletePlaylist.successful": "Playlist bol vymazaný"
}


const weekDay = {
    'weekDay.Monday': 'Pondelok',
    'weekDay.Tuesday': 'Utorok',
    'weekDay.Wednesday': 'Streda',
    'weekDay.Thursday': 'Štvrtok',
    'weekDay.Friday': 'Piatok',
    'weekDay.Saturday': 'Sobota',
    'weekDay.Sunday': 'Nedeľa',
}


const changeDashboard = {
    'changeDashboard.menu': 'Choď na radio dash'
}

export default {
    ...apiErrors,
    ...userRoles,
    ...defaultValidationErrors,
    ...settingsPage,
    ...userDetail,
    ...routes,
    ...usersPage,
    ...devices,
    ...invoices,
    ...orders,
    ...payouts,
    ...operations,
    ...payments,
    ...displays,
    ...companies,
    ...stores,
    ...zones,
    ...campaigns,
    ...commissions,
    ...media,
    ...streams,
    ...profilePage,
    ...createUserPage,
    ...deviceStatus,
    ...queryTable,
    ...loginPage,
    ...notificationsPage,
    ...screenTypeEnums,
    ...userStatuses,
    ...pageHeader,
    ...tableTexts,
    ...userDevicesPage,
    ...playlist,
    ...weekDay,
    ...changeDashboard,
    ...groups,
    ...dashboard,

};